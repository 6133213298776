import ElementUI from 'element-ui';
import axios from 'axios';
import * as math from "mathjs";
const Cart = {
  state: {
    cartarry: JSON.parse(sessionStorage.getItem('cartarry')) || [],//存储购物车商品的数组
  },
  getters:{
    doneTodosCount(state, getters) {
      return state.todos.filter( v => {
        return v.done;
      });
    },
    totalPrice(state,getters) { //计算总价格
      var zong = 0;
      var order = state.cartarry;
			for(var i=0;i<order.length;i++){
        if(!isNaN(parseFloat(order[i].buyNum)) && isFinite(order[i].buyNum)){
          zong=math.evaluate(zong+" + ("+order[i].buyNum+" * "+order[i].price+")");	
        }
					
			}
			return zong;
    
    }
  },
  mutations:{
    /* 添加购物车 */
    addStoreCart(state,tag){
      let cartJudge = state.cartarry.find(v=>tag.id==v.id);
      if(cartJudge){
        cartJudge.shuli++;
      }else{
        state.cartarry.push(tag);
      }
      ElementUI.Message("添加成功");
    },
    //清空购物车和删除购物车
    clearcart(state,id1){
      if(id1){
        state.cartarry = state.cartarry.filter(v => id1 != v.skuId);
      }else{
        state.cartarry=[]
      }
    },
    setCart(state,obj) { //设置购物车
      state.cartarry = obj;
    },
    // increaseCart(state, params){  //增加购物车++
    //   var jud = state.cartarry.find( v => v.skuId == params.skuId);
    //   if(jud){
    //     params.buyNum++;
    //     state.cartarry.forEach( v => {
    //       if(v.skuId == params.skuId){
    //           v.buyNum = params.buyNum
    //       }
    //     });
    //   }
      
    // },
    // reduceCart(state, params){  //减少购物车购物车--
    //   params.buyNum--;
    //   if(params.buyNum<=0){
    //     this.dispatch("clearcart",params.skuId);
    //     return false;
    //   }
    //   var jud = state.cartarry.find( v => v.skuId == params.skuId);
    //   if(jud){
    //     state.cartarry.forEach( v => {
    //       if(v.skuId == params.skuId){
    //           v.buyNum = params.buyNum
    //       }
    //     });
    //   }
    // },
  },
  actions:{
    async getCart({ commit }) { //获取购物
      const res = await axios.post('/api2/portal/auth/product/cart/myCart');
      // sessionStorage.setItem('cartarry',JSON.stringify(data)); //购物车储存
      let data = res.data.cartItems.map(v => {
          v.check = false;
          return v;
      });
      commit("setCart",data);
    },
    increaseCart({ commit }, params){  //增加购物车
      this.dispatch('addStoreCart',{
        skuId: params.skuId,
        buyNum: params.buyNum,
        pan: 1
      });
    },
    reduceCart({ commit }, params){  //减少购物车购物车
      params.buyNum--;
      if(params.buyNum<=0){
        this.dispatch("clearcart",params.skuId);
        return false;
      }
      this.dispatch('editCart',{
        skuId: params.skuId,
        buyNum: params.buyNum
      });
    },
    async clearcart({ commit }, params){ //清空购物车和删除购物车
      if(params){  //删除购物车
        console.log(params);
        const data = await axios.post('/api2/portal/auth/product/cart/del',{
          skuId:params
        });
        if(data.code === "000000"){
          commit("clearcart",params);
          ElementUI.Message({
            message: '删除成功！',
            type: 'success'
          });
        }
      }else{
        const data = await axios.post('/api2/portal/auth/product/cart/clear');
        if(data.code === "000000"){
          commit("clearcart");
          ElementUI.Message({
            message: '清空购物车！',
            type: 'success'
          });
        }
        
      }
    },
    async editCart({ commit,state }, params){
      const data = await axios.post('/api2/portal/auth/product/cart/change',params);
      if(data.code!="000000"){
        ElementUI.Message({
          message: "购物车数量改变失败",
          type: 'error'
        });
      }else{
        state.cartarry.forEach( v => {
          if(v.skuId == params.skuId){
              v.buyNum = params.buyNum
          }
        });
      }
    },
    async addStoreCart({ commit,state,dispatch }, params){ //添加购物车
      var params = {
        skuId: params.skuId,
        buyNum: params.buyNum || 1,
        pan: params.pan || 0
      };
      const data = await axios.post('/api2/portal/auth/product/cart/addCart',params);
      var jud = state.cartarry.find( v => params.skuId == v.skuId); //判断购物车内容是否存在
      
      if(data.code === "000000"){
        if(state.cartarry.length>0 && jud){  //判断购物车是否有这个产品
          // 有这个产品就改变他的数量
          state.cartarry = state.cartarry.map( v => {  
            if(v.productId == params.skuId){
              v.buyNum = v.buyNum + params.buyNum;
            }
            return v;
          });
        }else{
          dispatch("getCart");
        }
        if(params.pan!=1){
          ElementUI.Message({
            message: "添加成功",
            type: "success"
          });
        }
        
      }
      // state.cartarry=data.data;
      // commit("addStoreCart");
    }
  }
};



export default Cart;