
const Mock = require('mockjs');
//格式： Mock.mock( url, post/get , 返回的数据)；
Mock.mock('/home/banner', 'post', (res,ret)=>{ //首页banner
    ret=[{
        pcimg1: require("../../images/banner1.jpg"),
        pcimg2: require("../../images/banner1.jpg"),
        moimg1: require("../../images/banner1.jpg"),
        moimg2: require("../../images/banner1.jpg"),
      },
      {
        pcimg1: require("../../images/banner1.jpg"),
        pcimg2: require("../../images/banner1.jpg"),
        moimg1: require("../../images/banner1.jpg"),
        moimg2: require("../../images/banner1.jpg"),
      }];
    return ret;
});

Mock.mock('/home/product', 'post', (res,ret)=>{ //首页banner
    ret={
        ftitle: "我们的产品",
        text:"我们保证我们的风格符合您的口味。 定制定制，专为您和您的车辆量身定制。",
        content:[
          {
            src: require("../../images/chanpinlunimg1.jpg"),
            title: "轮 毂",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
          {
            src: require("../../images/chanpinlunimg2.jpg"),
            title: "空气动力套件",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
          {
            src: require("../../images/chanpinlunimg3.jpg"),
            title: "头 灯",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
          {
            src: require("../../images/chanpinlunimg4.jpg"),
            title: "进/排气套件",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
          {
            src: require("../../images/chanpinlunimg3.jpg"),
            title: "头 灯",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
          {
            src: require("../../images/chanpinlunimg3.jpg"),
            title: "头 灯",
            icon:[
              require("../../images/heiconimf1.png"),
              require("../../images/heiconimf1-1.png"),
            ]
          },
        ]
      };
    return ret;
});
Mock.mock('/home/xilie','post',(res,ret)=>{ //首页系列
    // console.log(JSON.parse(res.body).wode);
    ret={
        images: require("../../images/hm2bagwf1.jpg"),
        centent:[
          [
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg2.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg2.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg2.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg2.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
          ],
          [
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
            {
              bag: require("../../images/hm2bagwf1.jpg"),
              logoicon: require("../../images/hm2logoimg1.png"),
              children: [
                {
                  id: "1",
                  name: "日系"
                },
                {
                  id: "2",
                  name: "德系"
                }
              ]
            },
          ]
        ]
    };
    return ret;
});
Mock.mock('/homexinneng',"post",(res,ret)=>{ //首页产品性能
  ret={
    name: '- 我们最新的产品 -',
    title: "你的车变成真正的性能车",
    images1:require("../../images/zuixingchanimg1.jpg"),
    images2:require("../../images/zuixingchanimg1.jpg"),
    id: "1"
  }
  return ret;
});
Mock.mock('/homepingpai',"post",(res,ret)=>{ //首页品牌
  ret={
    id:"1",
    zhtitle: "品牌案例",
    center:[
      {
        title: "奥众汇国际贸易长期专注于奥迪改装升级领域",
        src: require("../../images/pingpaianliimgf1.jpg"),
        id: 1,
      },
      {
        title: "为越来越多的车主打造畅爽与安全并行的车辆体验",
        src: require("../../images/pingpaianliimgf2.jpg"),
        id: 2,
      },
      {
        title: "曼狄卡汽车配件有限公司，拥有顶尖的汽车改装工程师",
        src: require("../../images/pingpaianliimgf3.jpg"),
        id: 3,
      },
    ]
  }
  return ret;
});
Mock.mock('/shoppingCart','post',(res,ret)=>{
  ret=[
    {
      id: "1",
      url: require('../../images/gouruwchanimp1.png'),
      title: "【 BASE KIT 】 PRO DAMPER UCF1",
      content: "<p>尺寸：9.5J×21J值</p><p>宽度：9.5J×21</p>",
      max: "80",
      liang: 1,
      jiage: "10"
    },
    {
      id: "2",
      url: require('../../images/gouruwchanimp1.png'),
      title: "2【 BASE KIT 】 PRO DAMPER UCF1",
      content: "<p>尺寸：9.5J×21J值</p><p>宽度：9.5J×21</p>",
      max: "10",
      liang: 1,
      jiage: "20"
    }
  ];
  return ret;
});

Mock.mock('/aboutBanner','post',(res,ret)=>{ //关于我们banner
  ret={
      banvideo: "https://www.runoob.com/try/demo_source/movie.mp4",
      title: "关于Habitos",
      pcurl: require("../../images/aboutbanner1.jpg"),
      mobileurl: require("../../images/aboutbanner1.jpg")
  };
  return ret;
});

Mock.mock('/case','post',(res,ret)=>{ //品牌案例
  ret=[
    {
        id: 1,
        title: "BRABUS 800 - 时尚的 SUV",
        url: require("../../images/caseimgfew1.jpg")
    },
    {
        id: 2,
        title: "为越来越多的车主打造畅爽与安全并行的车辆体验",
        url: require("../../images/caseimgfew2.jpg")
    },
    {
        id: 3,
        title: "曼狄卡汽车配件有限公司，拥有顶尖的汽车改装工程师",
        url: require("../../images/caseimgfew3.jpg")
    },
    {
        id: 1,
        title: "BRABUS 800 - 时尚的 SUV",
        url: require("../../images/caseimgfew1.jpg")
    },
    {
        id: 2,
        title: "为越来越多的车主打造畅爽与安全并行的车辆体验",
        url: require("../../images/caseimgfew2.jpg")
    },
    {
        id: 3,
        title: "曼狄卡汽车配件有限公司，拥有顶尖的汽车改装工程师",
        url: require("../../images/caseimgfew3.jpg")
    },
    {
        id: 1,
        title: "BRABUS 800 - 时尚的 SUV",
        url: require("../../images/caseimgfew1.jpg")
    },
    {
        id: 2,
        title: "为越来越多的车主打造畅爽与安全并行的车辆体验",
        url: require("../../images/caseimgfew2.jpg")
    },
    {
        id: 3,
        title: "曼狄卡汽车配件有限公司，拥有顶尖的汽车改装工程师",
        url: require("../../images/caseimgfew3.jpg")
    },
    {
        id: 1,
        title: "BRABUS 800 - 时尚的 SUV",
        url: require("../../images/caseimgfew1.jpg")
    },
    {
        id: 2,
        title: "为越来越多的车主打造畅爽与安全并行的车辆体验",
        url: require("../../images/caseimgfew2.jpg")
    },
    {
        id: 3,
        title: "曼狄卡汽车配件有限公司，拥有顶尖的汽车改装工程师",
        url: require("../../images/caseimgfew3.jpg")
    },
];
  return ret;
});



Mock.mock('/prandliimgf','post',(res,ret)=>{ //品牌列表详情
  ret={
      banvideo: "https://www.runoob.com/try/demo_source/movie.mp4",
      title: "Weld Wheels",
      pcurl: require("../../images/weldwhbanner.jpg"),
      mobileurl: require("../../images/weldwhbanner.jpg")
  };
  return ret;
});




Mock.mock('/fapi/login','post',(res,ret)=>{ //登录接口
  var data = JSON.parse(res.body);
  console.log(data);
  const {tel,password}=data;
  if(tel=='13680311423' && password=='123456' || tel=='tim' && password=='fz123456'){
    ret={
      code:0,
      message:'登录成功',
      token:tel+'-'+(new Date().getTime()+60*60*1000)
    }
  }else{
    ret={
      code:1,
      message:'账号或密码错误'
    }
  }
  return ret;
});


/* 商务合作 */
Mock.mock('/cooperation,"post',(res,ret)=>{
  
});