
const Address = {
  state: { //仓库数据
    
  },
  mutations:{ //提交方法
    
  },
  actions: { //异步提交
  },
  getters:{  //和computed的效果差不多
   
  }
};

export default Address;