// import a from '../../views/ceshi/a.vue';  //品牌路由
// import b from '../../views/ceshi/b.vue';  //品牌路由
// import c from '../../views/ceshi/c.vue';  //品牌路由
export default [
  {
    path: "/brand",
    name: "Brand",
    component: ()=>import('../../views/brand/'),
    children:[
      {
        path: "",
        component: () => import('../../views/brand/Brand.vue'),
        meta:{
          title: "B2P"
        }
      },
      {
        path: "brandlist",
        name: "Brandlist",
        component: ()=>import('../../views/brand/Brand_list.vue'),
      },
      {
        path: "brandlistinfo/:userid/",
        name: "Brandlistinfo",
        component: ()=>import('../../views/brand/Brand_listinfo.vue'),
      },
    ]
  },
  // {
  //   path: "",
  //   component: ()=>import('../../views/ceshi/index.vue'),
  //   children:[
  //     {
  //       path: "/ceshi",
  //       name: "Ceshi",
  //       components: {
  //         // default: ()=>import('../../views/ceshi/a.vue'),
  //         a: a,
  //         b: b,
  //         c: c
  //       }
  //     }
  //   ]
  // }
]