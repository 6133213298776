
export default [
  {
    path: "/policy",
    name: "Policy",
    component: ()=>import('../../views/websiteinfo/Policy.vue'),
    meta:{
      title: "隐私政策"
    }
  },
  {
    path: "/policy2",
    name: "Policy2",
    component: ()=>import('../../views/websiteinfo/Policy2.vue'),
    meta:{
      title: "免责声明"
    }
  },
  {
    path: "/webmap",
    name: "Webmap",
    component: ()=>import('../../views/websiteinfo/Webmap.vue'),
    meta:{
      title: "网站地图"
    }
  }
]