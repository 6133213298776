/* 登录store */
const loginStore = {
  state: {
    /* 登录参数 */
    login: localStorage.getItem('login')!=""?JSON.parse(localStorage.getItem('login')):{
      id: "",
      imAccount: "",
      ticket: "",
      token:"",
    },
    
  },
  mutations: {
    /* 设置token */
    settoken(state,token){
      state.login=token;
    },
  },
  actions: {
  },
  getters:{
   
  }
};
/* End */
export default loginStore;