<template>
    <div id="header1">
        <!-- 占位符 通过判断headbox导航是否有<div class="hadheight"></div>进行显示站位 -->
		<!-- 头部 -->
		<header :class="{factive:loginToggle,headbox:true}">
			<div class="headbox-sec1">
				<div class="basecont">
					<div class="cont">
						<div class="lt">
							<div class="logo">
								<a href="/">
									<img :src="hadLogo" >
								</a>
							</div>
							
							<ul class="navbox">
								<li v-for="(item, index) in navList" :key="index">
									<router-link :to="item.url" class="tit">
										{{item.text}}
									</router-link>
								</li>
							</ul>
						</div>
						<div class="gt">
							<template v-if="manfixed">
								<a href="javascript:;" @click="guanShow(0)" class="f_portrait">
									<template v-if="typeof nologogdefault.modelName == 'string'">
										<div class="f_icon">
											<img :src="nologogdefault.logo" >
										</div>
										<div class="f_text">
											<span>{{nologogdefault.modelName}}</span>
											<i><img src="../assets/images/righticonfw1.png" ></i>
										</div>
									</template>
									<template v-else>
										<div class="f_text">
										<span>
											添加车辆
										</span>
										<i>
											<img src="../assets/images/righticonfw1.png" >
										</i>
									</div>
									</template>
									
								</a>
							</template>
							<template v-else>
								<a href="javascript:;" @click="guanShow(0)" class="f_portrait">
									<div class="f_icon">
										<img :src="proImgUrl" >
									</div>
									<div class="f_text">
										<span>{{accountMessage.nickName}}</span>
										<i><img src="../assets/images/righticonfw1.png" ></i>
									</div>
								</a>
							</template>
							<div class="f_lian">
								<!-- <a href="javascript:;" class="f_item f_transport">
									<div class="tit">
										<img src="../assets/images/headiconimf1.png" >
									</div>
								</a> -->
								<div class="f_item f_search">
									<a href="javascript:;" class="tit">
										<img src="../assets/images/headiconimf2.png" >
									</a>
									<form method="post" class="form">
										<div class="basecont">
											<div class="for_cont">
												<button type="submit" @click.prevent="searchSubmit()" class="icon f_sub iconfont icon-sousuo"></button>
												<input type="text" value="" @keypress.prevent.13="searchSubmit()" v-model="searchval" class="f_int" placeholder="输入关键词"/>
												<!-- <a href="javascript:;" class="close"><img src="../assets/images/headiconimf2.png" ></a> -->
											</div>
										</div>
									</form>
								</div>
								<div class="f_item f_sign" >
									<a href="javascript:;" @click="skipSgin()" class="tit">
										<img src="../assets/images/headiconimf3.png" >
									</a>
									<template>
										<div class="f_load" >
											<div class="f_ge">
												<div class="f_he">
													<div class="tijiao">
														<router-link to="/my/mymessage" class="toux">
															<div class="img"><img :src="proImgUrl" alt=""></div>
															<span>{{accountMessage.nickName}}</span>	
														</router-link>
														<router-link to="/my/mymessage" class="but"><span>进入个人中心</span><i class="icon iconfont icon-xiangyou"></i></router-link>
													</div>
													<!-- <div class="fd_form">
														<input type="text" placeholder="请输入订单号">
														<button class="f_but icon iconfont icon-sousuo"></button>
													</div> -->
													<!-- <div class="fzfont basefont24">
															<div class="tit">
																	扫码去APP查看
															</div>
													</div> -->
													<div class="f_li">
														<router-link to="/my/myaddress" class="it">
															<i><img src="../assets/images/fdingicon1.png" alt=""></i>
															<span>收货地址</span>
														</router-link>
														<router-link to="/my/mynotice" class="it">
															<i><img src="../assets/images/fdingicon4.png" alt=""></i>
															<span>消息通知</span>
														</router-link>
														<router-link to="/my/mycollection" class="it">
															<i><img src="../assets/images/fdingicon3.png" alt=""></i>
															<span>商品收藏</span>
														</router-link>
													</div>
												</div>
												<div class="f_wen">
													<router-link to="/faq">常见问题</router-link>
													<a href="javascript:;" @click="loginOut">退出登录</a>
												</div>
											</div>
										</div>
									</template>
								</div>
								<a href="javascript:;" @click="showShoppin()" class="f_item f_shopping">
									<div class="tit">
										<img src="../assets/images/headiconimf4.png" >
									</div>
								</a>
							</div>
							<div class="basemenubut">
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- pc端导航 -->
			<div class="headbox-sec2">
				<div class="basecont">
					<div class="cont">
						<div class="f_item active">
							<div class="lt">
								<div class="text">
									{{p2bcont.context}}
								</div>
								<router-link to="/product" class="hm-but f_but">
									<span>全部产品</span>
									<i>
										<img src="../assets/images/jiantouyo1.png" >
										<img src="../assets/images/jiantouyo2.png" >
									</i>
								</router-link>
							</div>
							<div class="gt">
								<div class="f_lo">
									<div class="list" v-for="(item,index) in p2bcont.children" :key="index">
										<!-- <router-link :to="{name: 'Productlist',params:{pan: 1,id:item.id,title:item.name}}" class="li" :title="item.name">
											<div class="img">
												<img :src="item.icon" :alt="item.name" >
											</div>
											<div class="poin">
												<i><img :src="item.pcIcon" ></i>
												<span>{{item.name}}</span>
											</div>
										</router-link> -->
										<a href="javascript:;" 
										 	@click="navTo(1,item.id,item.name)"
											class="li" 
										 	:title="item.name">
											<div class="img">
												<img :src="item.icon" :alt="item.name" >
											</div>
											<div class="poin">
												<i><img :src="item.pcIcon" ></i>
												<span>{{item.name}}</span>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="f_item">
							<div class="lt">
								<div class="text">
									{{b2p.context}}
								</div>
								<router-link to="/brand" title="全部地区" class="hm-but f_but">
									<span>{{navData.anniu_text}}</span>
									<i>
										<img src="../assets/images/jiantouyo1.png" >
										<img src="../assets/images/jiantouyo2.png" >
									</i>
								</router-link>
							</div>
							<div class="gt">
								<div class="f_map">
									<div class="f_qu">
										<div class="f_tit">
											按地区分类
										</div>
										<div class="di" >
											<router-link :to="{name: 'Brandlist',query:{title:item}}" v-for="(item,index) in b2pcont"  :key="index">
												<span @mouseleave="b2pmouse(index)">{{item}}</span>
											</router-link>
										</div>
									</div>
									<div class="f_img">
										<template v-for="(item, index) in imageList">
											<div :class="{img:true,on:imon==index}" :key="index">
												<img :src="item" >
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 移动端导航 -->
			<div class="headbox-sec3">
				<div class="basecont">
					<div class="navbox">
						<ul>
							<li>
								<router-link to="/product" class="tit">
									<span>P2B</span>
								</router-link>
							</li>
							<li>
								<router-link to="/brand" class="tit">
									B2P
								</router-link>
							</li>
							<li>
								<router-link to="/case" class="tit">
									品牌案例
								</router-link>
							</li>
							<li>
								<router-link to="/faq" class="tit">
									<span>常见问题</span>
								</router-link>
							</li>
							<li>
								<router-link to="/cooperation" class="tit">
									<span>商务合作</span>
								</router-link>
							</li>
							<li>
								<router-link to="/about" class="tit">
									<span>关于我们</span>
									<i class="icon iconfont icon-xiangyou"></i>
								</router-link>
								<ul>
									<li><router-link to="/contact">联系我们</router-link></li>
								</ul>
							</li>
						</ul>
					</div>
					<template v-if="manfixed">
						<a href="javascript:;" @click="guanShow(0)" class="f_portrait">
							<div class="f_text">
								<span>添加车辆</span>
								<i class="icon iconfont icon-xiangyou"></i>
							</div>
						</a>
					</template>
					<template v-else>
						<a href="javascript:;"  @click="guanShow(0)" class="f_portrait">
							<div class="f_icon">
								<img :src="proImgUrl" >
							</div>
							<div class="f_text">
								<span>{{accountMessage.nickName}}</span>
								<i class="icon iconfont icon-xiangyou"></i>
							</div>
						</a>
					</template>
					
				</div>
			</div>
		</header>
		
		<!-- 购物车弹窗 -->
		<div class="shopfixed">
			<div class="bag" @click="hideShoppin()"></div>
			<div class="cont">
				<div class="f_hd">
					<div class="tit">购物车</div>
					<a href="javascript:;" @click="hideShoppin()" class="close"><img src="../assets/images/shpoclosegu1.png" alt=""></a>
				</div>
				<div class="f_jut">
					<div v-for="(item,index) in shopfixed2" :key="index" class="item">
						<div class="img">
							<img :src="item.productImg" :alt="item.skuTitle">
						</div>
						<div class="font">
							<div class="tit">{{item.skuTitle}}</div>
							<div class="text" v-html="item.skuSubtitle"></div>
							<div class="text"><p>价格：￥{{item.price}}</p></div>
							<div class="f_ne">
								<div class="fw_zen">
									<a href="javascript:;" @click="reduce(index)" class="f_jian">-</a>
									<input type="number"
										@change="maxShop(index)"
										
									 	v-model="item.buyNum">
									<a href="javascript:;" @click="add(index)"  class="f_jia">+</a>
								</div>
								<a href="javascript:;" @click="clearcart(item.skuId)" class="f_but">消除</a>
							</div>
						</div>
					</div>
				</div>
				<div class="f_fot">
					<div class="font">
						<div class="text">
							<p>添加订单备注</p>
							<p>结账时计算运费和税费</p>
						</div>
						<div class="shu">¥ {{shoppingCount}}</div>
					</div>
					<a href="javascript:;" @click="orderdet()" class="hm-but f_but">
						<span>购物车列表</span>
						<i>
							<img src="../assets/images/jiantouyo1.png" >
							<img src="../assets/images/jiantouyo2.png" >
						</i>
					</a>
				</div>
			</div>
		</div>
		<!-- 车辆管理 -->
		<template v-if="manfixed">  <!-- 登录前 -->
			<div class="manage_fixed">
				<div class="bag" @click="guanHide()"></div>
				<div class="cont">
					<a href="javascript:;" @click="guanHide()" class="close"><img src="../assets/images/closeiconf1.png" alt=""></a>
					<div class="f_zhong">
						<div class="f_cont on">
							<div class="f_guan">
								<div class="tit basefont30">车辆管理</div>
								<div class="text">
									<p>添加车辆以获取更加匹配您车型的产品结果。当前您还未登录，仅支持添加1款车型，登录后可添加更多车型。<router-link to="/sign/scancode">立即登录</router-link></p>
								</div>
								<!-- 默认 -->
								<div class="f_default" v-show="nologogdefault.modelName.length">
									<div class="img">
										<img :src="nologogdefault.logo" alt="">
									</div>
									<div class="font">
										<div class="name">目前选择的车辆</div>
										<div class="f_tit">{{nologogdefault.modelName}}</div>
									</div>
									<a href="javascript:;" @click="removeDefault()" class="f_but">
										<img src="../assets/images/removeicondela1.png" alt="">
									</a>
								</div>
								<div class="fz_but_box" v-show="nologogdefault.modelName.length<=0">
									<!-- <a href="javascript:;" @click="logopin1()" class="f_zi">请选择车辆</a> -->
									<a href="javascript:;" @click="logopin1()" id="" class="f_but hm-but">
											<span>+添加车辆</span>
										</a>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_ping">
								<div class="manage_tit">
									<div class="tit basefont30">请选择品牌</div>
								</div>
								<div class="f_jut">
									<div class="f_plogo">
										<div :class="{item:true}" v-for="(item,index) in plogo" :key="index">
											<a href="javascript:;" @click="zhuan(index,item.id)" class="img">
												<img :src="item.logo" :alt="item.brandName">
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_data">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(1)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择年份</div>
								</div>
								<div class="f_select" v-if="fdata.nianfen">
									<div class="f_tit" @click="pinToggle($event)">
										<span>{{fdata.nian}}</span>
										<i><img src="../assets/images/nianfenselelc1.png"></i>
									</div>
									<div class="f_jut">
										<div class="f_list">
											<a href="javascript:;" class="li" @click="yaerSelect(index)" v-for="(item,index) in fdata.nianfen" :key="index">
												<i></i>
												<span>{{item.issueYear}}</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_xing">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(2)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择体型</div>
								</div>
								<div class="f_jut">
									<div :class="{item:true,active:index==tix}" v-for="(item,index) in tixing" :key="index">
										<div @click="tixinf(index)" class="li basefont30">
											{{item.shape}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_hao">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(3)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择型号</div>
								</div>
								<div class="f_jut">
									<template v-for="(item,index) in xinghao">
										<div 
											:class="{item:true,active1:index==xh.in1}"  
											@click="xuanzeCl(index,item.id,item.modelCategory,1,item)"  
											v-if="item.modelList.length"  
											:data-id="item.id" 
											:key="index">
											<div class="list">
												<div class="tit">
													<div class="name basefont30">{{item.modelCategory}}</div>
													<i v-if="item.modelList"><img src="../assets/images/xuanzexingfaicoimgf1.png" alt=""></i>
												</div>
												<div class="jut" v-if="item.modelList">
													<a href="javascript:;" :class="{li: true,active2: in1==xh.in2}" @click.stop="xuanzeCl(in1,it.id,it.model,2,item)" v-for="(it,in1) in item.modelList" :key="in1">{{it.model}}</a>
												</div>
											</div>
										</div>
										<div 
											:class="{item:true,active1:index==xh.in1}"  
											@click="xuanzeCl(index,item.id,item.modelCategory,1,item)" 
											v-else 
											:data-id="item.id" 
											:key="index">
											<div class="list">
												<div class="tit">
													<div class="name basefont30">{{item.modelCategory}}</div>
												</div>
											</div>
										</div>
									</template>
									
								</div>
								<a href="javascript:;" @click="noLoginBaocun()" class="hm-but f_but">
									<span>保存设置</span>
									<i>
										<img src="../assets/images/jiantouyo1.png" >
										<img src="../assets/images/jiantouyo2.png" >
									</i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else>  <!-- 登录后 -->
			<div class="manage_fixed">
				<div class="bag" @click="guanHide()"></div>
				<div class="cont">
					<a href="javascript:;" @click="guanHide()" class="close"><img src="../assets/images/closeiconf1.png" alt=""></a>
					<div class="f_zhong">
						<div class="f_cont on">
							<div class="f_guan">
								<div class="tit basefont30">车辆管理</div>
								<div class="text">
									添加车辆以获取更加匹配您车型的产品结果。
								</div>
								<!-- 默认 -->
								<div class="f_default" v-show="default1!=''">
									<div class="img">
										<img :src="default1.logo" alt="">
									</div>
									<div class="font">
										<div class="name">目前选择的车辆</div>
										<div class="f_tit">{{default1.modelName}}</div>
									</div>
									<a href="javascript:;" @click="removeDefault()" class="f_but">
										<img src="../assets/images/removeicondela1.png" alt="">
									</a>
								</div>
								<!-- <div class="f_default" v-show="default1==''">
									<a href="javascript:;" @click="logopin1()" class="f_zi">请选择车辆</a>
								</div> -->
								<div class="fz_but_box" v-show="default1==''">
										<a href="javascript:;" @click="logopin1()" id="" class="f_but hm-but">
											<span>+添加车辆</span>
										</a>
								</div>
								<!-- 其他车辆 -->
								<div class="f_list" v-show="cartcont!=''">
									<div class="f_tit">
										<div class="label">你的其他车辆</div>
										<a href="javascript:;" @click="logopin1()" id="" class="f_but hm-but">
											<span>+添加车辆</span>
										</a>
									</div>
									<div class="f_nei">
										<div class="li" v-for="(item,index) in cartcont" :key="index">
											<div class="title">
												{{item.modelName}}
											</div>
											<div class="but">
												<a href="javascript:;" @click="defaultSet(index,item.id)" class="she">设置为当前车辆</a>
												<a href="javascript:;" @click="removeCart(index,item.id)" class="remove"><img src="../assets/images/altremove.png" alt=""></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_ping">
								<div class="manage_tit">
									<div class="tit basefont30">请选择品牌</div>
								</div>
								<div class="f_jut">
									<div class="f_plogo">
										<div :class="{item:true}" v-for="(item,index) in plogo" :key="index">
											<a href="javascript:;" @click="zhuan(index,item.id)" class="img">
												<img :src="item.logo" :alt="item.brandName">
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_data">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(1)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择年份</div>
								</div>
								<div class="f_select" v-if="fdata.nianfen">
									<div class="f_tit" @click="pinToggle($event)">
										<span>{{fdata.nian}}</span>
										<i><img src="../assets/images/nianfenselelc1.png"></i>
									</div>
									<div class="f_jut">
										<div class="f_list">
											<a href="javascript:;" class="li" @click="yaerSelect(index)" v-for="(item,index) in fdata.nianfen" :key="index">
												<i></i>
												<span>{{item.issueYear}}</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_xing">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(2)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择体型</div>
								</div>
								<div class="f_jut">
									<div :class="{item:true,active:index==tix}" v-for="(item,index) in tixing" :key="index">
										<div @click="tixinf(index)" class="li basefont30">
											{{item.shape}}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="f_cont">
							<div class="f_hao">
								<div class="manage_tit">
									<a href="javascript:;" @click="cartToggle(3)" class="fanhui"><img src="../assets/images/cheshangyigeimgfa1.png" alt=""></a>
									<div class="tit basefont30">请选择型号</div>
								</div>
								<div class="f_jut">
									<template v-for="(item,index) in xinghao">
										<div 
											:class="{item:true,active1:index==xh.in1}"  
											@click="xuanzeCl(index,item.id,item.modelCategory,1,item)"  
											v-if="item.modelList.length"  
											:data-id="item.id" 
											:key="index">
											<div class="list">
												<div class="tit">
													<div class="name basefont30">{{item.modelCategory}}</div>
													<i v-if="item.modelList"><img src="../assets/images/xuanzexingfaicoimgf1.png" alt=""></i>
												</div>
												<div class="jut" v-if="item.modelList">
													<a href="javascript:;" class="li" @click.stop="xuanzeCl(in1,it.id,it.model,2,item)" v-for="(it,in1) in item.modelList" :key="in1">{{it.model}}</a>
												</div>
											</div>
										</div>
										<div 
											:class="{item:true,active1:index==xh.in1}"  
											@click="xuanzeCl(index,item.id,item.modelCategory,1,item)" 
											v-else 
											:data-id="item.id" 
											:key="index">
											<div class="list">
												<div class="tit">
													<div class="name basefont30">{{item.modelCategory}}</div>
												</div>
											</div>
										</div>
										
									</template>
									
								</div>
								<a href="javascript:;" @click="baocun()" class="hm-but f_but">
									<span>保存设置</span>
									<i>
										<img src="../assets/images/jiantouyo1.png" >
										<img src="../assets/images/jiantouyo2.png" >
									</i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		
    </div>
</template>

<script>
import * as math from "mathjs";
import { mapState,mapMutations,mapActions,mapGetters } from 'vuex';

export default{ 
	data(){
		return {
			manfixed: this.$store.state.loginStore.login=="" ||this.$store.state.loginStore.login==null?true:false,
			shopfixed:[],
			count: "",
			/* 车辆管理默认设置 */
			default1: [],
			// default1: {
			// 	title: "2020年奔驰E300",
			// 	url: require('../assets/images/pingpailogoimgf2.jpg'), 
			// },
			/* 车辆管理 */
			cartcont:[],
			/* 请选择品牌 */
			plogo:[],
			pin: 0,
			/* 请选择年份 */
			fdata:{
				nian:"请选择年份",
				nianfen:[],
			},
			/* 请选择体型 */
			tixing:[
				{
					title: "SEDAN"
				},
				{
					title: "COUPE"
				},
				{
					title: "AMG"
				},
				{
					title: "CLK"
				}
			],
			tix:"没有",
			/* 选择型号 */
			xinghao:[
				{
					modelList:[]
				}
			],
			xh: {
				in1: -1,
				in2: -1,
			},
			/* pc导航p2b */
			p2bcont: {
				// context:"Habitos的两位创始人都是90后，对于改装车文化有着极深的热爱，奔赴海外深造多年，深受国外改装文化影响，但是一切的起点，都来源于儿时的那辆改装四驱车。多年来对这份文化的热忱使两位创始人决定，要将这份文化，心得，快乐与国内改装车爱好者分享，共同享受。",
				children:[]
			},
			// p2bcont:{
			// 	context:"Habitos的两位创始人都是90后，对于改装车文化有着极深的热爱，奔赴海外深造多年，深受国外改装文化影响，但是一切的起点，都来源于儿时的那辆改装四驱车。多年来对这份文化的热忱使两位创始人决定，要将这份文化，心得，快乐与国内改装车爱好者分享，共同享受。",
			// 	children:[
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg1.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg2.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg3.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg4.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg5.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg6.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg7.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 		{
			// 			title: "空气动力套件",
			// 			url: require('../assets/images/dongliimg8.jpg'),
			// 			id: "1",
			// 			icon: require('../assets/images/headiconimf1.png'),
			// 		},
			// 	]	
			// },
			/* pc导航b2p */
			b2pcont:[
				// {
				// 	title: "北美",
				// 	id: 1
				// },
				// {
				// 	title: "亚洲",
				// 	id: 2
				// },{
				// 	title: "中东",
				// 	id: 3
				// },
				// {
				// 	title: "欧洲",
				// 	id: 4
				// },
				// {
				// 	title: "南美",
				// 	id: 5
				// },
				// {
				// 	title: "非洲",
				// 	id: 6
				// }
			],
			imageList:"", //b2p图片
			imon: 0, //图片选中
			/* 搜索结果 */
			searchval: "",
			loginToggle: this.$store.state.loginStore.login=="" ||this.$store.state.loginStore.login==null?true:false
			/* 车辆管理位置 */
			,carpoin: [],
			b2p:{
				context:''
			},
			nologogdefault: sessionStorage.getItem("nologogdefault") ? 
				JSON.parse(sessionStorage.getItem("nologogdefault")) : {
				modelName:[],
				logo: '',
			},
			navData: {},
			navList: [
				{
					url: '/product',
					text: 'P2B'
				},
				{
					url: '/brand',
					text: 'B2P'
				},
				{
					url: '/case',
					text: '品牌案例'
				},
				{
					url: '/faq',
					text: '常见问题'
				},
				{
					url: '/cooperation',
					text: '商务合作'
				},{
					url: '/about',
					text: '关于我们'
				}
			],
			hadLogo: ""
		}
	},
	created(){
		
		/* 导航 */
		var _this1 = this;
		$(function(){
			function fixed(){
				var he = $(window).outerHeight() - ($(".shopfixed .f_fot").outerHeight() + $(".shopfixed .f_hd").outerHeight());
				$(".shopfixed .f_jut").css("height",he+"px");
			}
			
			$(".f_shopping").click(function(){
				fixed();
			});
			window.onresize=function(){
				fixed();
			};
			function navFade(){
			var i = $(window).scrollTop();
			var obj = $(".headbox");
			$(window).scroll(function(){
				if($(this).scrollTop()>i&& $(this).scrollTop()>50){
				i = $(this).scrollTop();
				obj.addClass("headbox3");
				}else{
				i = $(this).scrollTop();
				obj.removeClass("headbox3");
				}
			});
			}
			navFade();
			if($(".hadheight").length){
				$(".hadheight").show();
				$(".headbox").addClass("headbox2")
			}
			// 搜索框
			$(".headbox-sec1 .gt .f_lian .f_search .tit").click(function(){
				$(this).parent().find(".form").stop().slideToggle();
			});
			/* pc下拉导航 */
			$(".headbox-sec1 .cont .lt .navbox>li").hover(
				function(){
					var _index = $(this).index();
					if(_index==0 || _index==1){
						$(".headbox-sec2").stop().slideDown();
						$(".headbox-sec2 .basecont .cont .f_item").eq(_index).addClass("active").siblings().removeClass("active");
					}else{
						$(this).find("ul").stop().slideDown();
					}
				},
				function(){
					var _index = $(this).index();
					$(".headbox-sec2").stop().slideUp();
					if(_index!=0 || _index!=1){
						$(this).find("ul").stop().slideUp();
					}
				}
			);
			$(".headbox-sec2 .cont").hover(
				function(){
					$(this).parents(".headbox-sec2").stop().slideDown();
				},
				function(){
					$(this).parents(".headbox-sec2").stop().slideUp();
				}
			);
			
			/* 手机端导航 */
			$(".headbox-sec1 .cont .gt .basemenubut").click(function(){
				$(this).toggleClass("basemenubut2");
				$(".headbox-sec3").stop().slideToggle();
			});
			$(".headbox-sec3 .navbox ul > li .tit").click(function(e){
				if($(this).parent().find("li").length!=0){
					e.preventDefault();
					$(this).parent().toggleClass("active");
					$(this).next("ul").stop().slideToggle();
				}
			});
			/* 物流显示 */
			// $(".f_transport").click(function(){
			// 	$(".wufixed").fadeIn();
			// });
			// $(".wufixed .bag,.wufixed .close").click(function(){
			// 	$(".wufixed").fadeOut();
			// });
			$(document).on("click",".f_data .f_select .f_list .li",function(e){
				_this1.fdata.nian=$(this).find("span").html();
				// _this1.cartToggle(3);
			});
			// $(".f_data .f_select .f_tit").click(function(e){
				
			// });
			
			// $(document).on("click",".f_hao .f_jut .item .list .tit",function(){
			// 	$(this).parents(".item").toggleClass("active1").siblings().removeClass("active1")
			// 	if($(this).parent().find(".jut a").length!=0){
			// 		$(this).parents(".item").find(".jut").stop().slideToggle();;
			// 		$(this).parents(".item").siblings().find(".jut").stop().slideUp()
			// 	}
			// });
			
		});
		/* End */
		/* 获取车辆管理数据 */
		if(this.$store.state.loginStore.login){
			this.getCarReg();
		}
		this.addCart();
		/* End */
		/* 获取购物车 */
		this.getCart();

		/* 获取导航内容文案 */
		
		this.$axios.post('/api/Column/getText').then(res =>{
			
			this.navData = res.data;
			this.$emit("brandSelect",res.data.b2p);
			for(var i=0;i<this.navList.length;i++){
				this.navList[i].text = res.data.nav['nav_text' + (i+1)]
			}
		}).catch(error => {
			console.log(error);
		});
		// 导航logo
		this.$axios.post('/api/Column/getConfig').then(res =>{

			this.hadLogo = res.data.logo
		}).catch(error => {
			console.log(error);
		});
		
	},
	mounted(){
		var _this = this;
		function navHeight(){
			$(".headbox-sec3").css("height",($(window).height()-$(".headbox-sec1").height())+"px");
		}
		navHeight();
		window.onresize=function(){
			navHeight()
		}

		/* 获取用户信息 */
		if(this.$store.state.loginStore.login){
			const token = this.$store.state.loginStore.login.token;
			this.$axios.post('/api2/portal/auth/user/getUserInfo').then(res=>{
					this.$store.state.UserInfo.userConten=res.data;
					this.$store.state.UserInfo.accountMessage = {...res.data};
			}).catch(error=>{
					console.log(error);
			});
		}

		/* axios */
		this.$axios.all([this.HeadP2BAjax(),this.headb2p(),this.HeadP2BAjax2()]).then(res => {
			this.p2bcont.children=res[0].data.data;
			this.p2bcont.context=res[2].data;
			/* b2p数据 */
			this.b2p.context=res[1].data;
			// 返回导航下拉产品的数据
			this.$emit("hedProduct",this.p2bcont.children)
		}).catch(error => {
			console.log(error);
		});

		/* 获取B2P下拉分类 */
		this.$axios.post('/api2/portal/article/product/attr/getByIdForPC',{
			id: "1632238426241732611"
		}).then(res => {
			this.b2pcont = res.data.attrValueList;
			this.imageList =res.data.imageList;
			this.$emit("b2listwt",res.data);
		}).catch(error => {
			console.log(error);
		});

		

	},
	computed:{
		// shoppingCount:function(){ //计算购物车总价
		// 	var zong = 0;
		// 	for(var i=0;i<this.shopfixed.length;i++){
		// 		zong=math.evaluate(zong+" + ("+this.shopfixed[i].liang+" * "+this.shopfixed[i].jiage+")");		
		// 	}
		// 	zong;
		// 	return zong;
		// },
		...mapState({
				shopfixed2: state => state.Cart.cartarry,
				accountMessage: state => state.UserInfo.accountMessage
		}),
		...mapGetters({
			shoppingCount: 'totalPrice'
		}),
		proImgUrl(){
			let imgUrl = this.accountMessage.headerImg;
			if(imgUrl==''){
				imgUrl=require('../assets/images/filesimgf1.jpg');
			}
			return imgUrl;
		},
		/* 车辆管理默认数据 */
		// default1(){
		// 	let arr = this.cartcont;
		// 	let ret = [];
		// 	for(let x of arr){
		// 		if(x.isDefault==1){
		// 			ret=x;
		// 		}
		// 	}
		// 	return ret
		// }
	},
	methods:{
		...mapActions([
			'getCart',
			'increaseCart',
			'reduceCart',
			'clearcart',
			'editCart'
		]),
		orderdet(){
			this.$router.push({path: "/shoppingcart2"});
		},
		// ...mapMutations([
		// 	'increaseCart',
		// 	'reduceCart'
		// ]),
		/* 导航p2b数据 */
		HeadP2BAjax(){
				return this.$axios.post("/api2/portal/product/category/list",{
					current: 1,
					size: 8
				});
		},
		HeadP2BAjax2(){
				return this.$axios.post("/api2/portal/order/growthman/getP2BDesc");
		},
		headb2p(){ //获取下拉b2p  文字内容
				return this.$axios.post("/api2/portal/order/growthman/getB2PDesc");
		},
		pinToggle(event){
			var e = e || event;
			var thar = $(".f_data .f_select .f_tit");
			$(".f_data .f_select .f_tit").parent().find(".f_jut").stop().slideToggle();
			e.stopPropagation();
			$(document).one("click",function(e){
				e.stopPropagation();
				thar.parent().find(".f_jut").stop().slideUp();
			});
		},
		searchSubmit:function(){
			if(this.searchval==""){
				this.$message('搜索结果不能为空');
				return false;
			}
			
			this.$router.push({path:"/search",query:{title:this.searchval}});
			
		},
		guanHide:function(){
			$(".manage_fixed").fadeOut();
		},
		guanShow:function(){
			$(".manage_fixed").fadeIn();
		},
		logopin1(){
			if(this.plogo.length){
				this.carpoin=[];
				this.cartToggle(1);
			}else{
				this.$message({
							message: '没有车辆选择！',
							type: 'error',
					});
			}
		},
		xuanzeCl(_in,id,title,pan,item){
			this.carpoin[3]=id;
			if(pan==1){
				this.xh.in1 = _in;
				if($(".f_hao .f_jut .item").eq(_in).find(".list .tit").parents(".item").hasClass("active1")){
					$(".f_hao .f_jut .item").eq(_in).find(".list .tit").parents(".item").removeClass("active1").siblings().removeClass("active1")
				}else{
					$(".f_hao .f_jut .item").eq(_in).find(".list .tit").parents(".item").addClass("active1").siblings().removeClass("active1")
				}
				if($(".f_hao .f_jut .item").eq(_in).find(".list .tit").parent().find(".jut a").length!=0){
					$(".f_hao .f_jut .item").eq(_in).find(".list .tit").parents(".item").find(".jut").stop().slideToggle();;
					$(".f_hao .f_jut .item").eq(_in).find(".list .tit").parents(".item").siblings().find(".jut").stop().slideUp()
				}
				this.xh.in2 = -1;
			}else{
				this.xh.in2 = _in;
			}
			console.log(item);
			
			// $(".manage_fixed .cont .f_cont .f_hao .f_jut .item.active1 .list .jut .li").eq(_in).addClass("active2").siblings().removeClass("active2");
			/* 未登录的默认名称 */
			if(!this.$store.state.loginStore.login){
				if(this.xh.in2!=-1){
					this.nologogdefault.id = item.modelList[this.xh.in2].id; 
				}else{
					this.nologogdefault.id = id; 
				}
				
				this.nologogdefault.denglui = 1;
				this.nologogdefault.modelName[3] = title;
				console.log(this.nologogdefault);
				
			}
		},
		async zhuan(index,_id){ //品牌选择
			// console.log();
			// 获取年份
			var res = await this.$axios.post('/api2/portal/product/carBrand/listYearsByCarBrandId',{id:_id});				
			if(res.code=="000000"){
				this.fdata.nianfen = res.data;
				//跳转位置
				this.carpoin[0]=index;
				this.pin=index;
				this.cartToggle(2);
			}else{
				this.$message({
					message: res.message,
					type: 'error',
				});
			}
			/* 未登录的默认名称 */
			if(!this.$store.state.loginStore.login){
				this.nologogdefault.modelName[0] = this.plogo[index].brandName;
				this.nologogdefault.logo = this.plogo[index].brandPic;
			}
			
			
		},
		async yaerSelect(index){ //年份选择
				// console.log(this.fdata.nianfen[index]);
				this.carpoin[1]=index;
				var res = await this.$axios.post('/api2/portal/product/carBrand/listShapeByParams',{
					brandId: this.plogo[this.carpoin[0]].id,
					issueYear: this.fdata.nianfen[index].issueYear,
				});
				if(res.code == "000000"){

					this.tixing = res.data;
					this.cartToggle(3);
				}else{
					this.$message({
							message: res.message,
							type: 'error',
					});
				}
				/* 未登录的默认名称 */
				if(!this.$store.state.loginStore.login){
					this.nologogdefault.modelName[1] = this.fdata.nianfen[index].issueYear;
				}
				
		},
		async tixinf(index){  //体型选中
			this.tix=index;
			this.carpoin[2]=index;

			var res = await this.$axios.post('/api2/portal/product/carBrand/listCategoryByParams',{
					brandId: this.plogo[this.carpoin[0]].id,
					issueYear: this.fdata.nianfen[1].issueYear,
					shape: this.tixing[index].shape
				});
				// console.log(res);
				if(res.code == "000000"){
					this.xinghao = res.data;
					this.cartToggle(4);
				}else{
					this.$message({
							message: res.message,
							type: 'error',
					});
				}

			/* 未登录的默认名称 */
			if(!this.$store.state.loginStore.login){
				this.nologogdefault.modelName[2] = this.tixing[index].shape;
			}
			
			
		},
		cartToggle:function(index){ //车辆管理切换
			if(index==0){
				this.tix=-1;
				this.xh.in1 = -1;
				this.xh.in2 = -1;
			}
			
			$(".manage_fixed .cont .f_zhong .f_cont").eq(index).addClass("on").siblings().removeClass("on");
		},
		defaultSet:function(index,id){ //车辆管理默认设置
			let _id = id;
			if(index==-100){
				// console.log(this.cartcont);
				// var _in = this.cartcont(it => {
					
				// });
				this.default1 = this.nologogdefault;

			}else if(index=="设置默认"){
				
			} else{
				this.default1 = this.cartcont[index];
			}
			this.$axios.post("/api2/portal/auth/user/carInfo/setMyDefaultCar",{
				id:_id
			}).then(res => {
				if(res.code=="000000"){
					if(index!=-100 && index!="设置默认"){
						this.getCarReg();
						this.$message({
								message: '设置默认成功!',
								type: 'success',
						});
					}
				}
			}).catch(error => {
				console.log(error);
			});
		},
		removeCart:function(index,id){ //车辆管理删除
			
			this.$axios.post("/api2/portal/auth/user/carInfo/deleteMyCar",{id:[id]}).then(res => {

				if(res.code=="000000"){
					this.cartcont.splice(index,1);
					
					this.$message({
							message: '删除成功!',
							type: 'success',
					});
					
				}else{
					this.$message({
							message: res.message,
							type: 'error',
					});
				}
			}).catch(error => {
				console.log(error);
			});
		},
		removeDefault:function(){  //车辆管理默认删除
			if(this.$store.state.loginStore.login){
					let _in = this.cartcont.findIndex( it => {
					return it.isDefault==1;
				});
				console.log(_in);
				this.removeCart(_in,this.cartcont[_in].id,"删除默认");
			}
			this.default1=[];

			sessionStorage.removeItem("nologogdefault");
			this.nologogdefault = {
				modelName:[],
				logo: '',
			};
		},
		removeShopFixed:function(_index,ite){ //购物车删除
			this.shopfixed2.splice(_index,1);
		},
		showShoppin:function(){ //显示购物车
			// this.getCart();  //获取购物车内容
			$(".shopfixed").fadeIn();
		},
		hideShoppin:function(){ //隐藏购物车
			$(".shopfixed").fadeOut();
		},
		add:function(index){ //购物车加
			this.increaseCart({skuId:this.shopfixed2[index].skuId,buyNum: 1});
		},
		reduce:function(index){ //购物车减
			this.reduceCart({skuId:this.shopfixed2[index].skuId,buyNum:this.shopfixed2[index].buyNum});
		},
		maxShop:function(index){ //购物车最大值
			
			var params = {
				skuId:this.shopfixed2[index].skuId,
				buyNum: Number(this.shopfixed2[index].buyNum)
			}
			this.editCart(params)
			// if(this.shopfixed[index].liang>=Number(this.shopfixed[index].max)){
			// 	this.shopfixed[index].liang=this.shopfixed[index].max;
			// 	this.$message('该产品库存仅剩下'+this.shopfixed[index].max);
			// }
		},
		/* 保存   车辆管理信息 */
		baocun(_id){
			if($(".f_hao .f_jut .item.active1").length==0){
				this.$message({
						message: '请选择型号！',
						type: 'error',
				});
				return false;
			}
			let brandParamId = this.carpoin[3];
			if(_id){
				brandParamId = _id;
			}
			

			this.$axios.post("/api2/portal/auth/user/carInfo/addMyCar",
			{
				brandParamId: brandParamId
			}
			).then(res =>{

				if(res.code=="000000"){
					
					this.getCarReg();
					this.cartToggle(0);

					this.$message({
							message: '保存成功!',
							type: 'success',
					});
				}
			}).catch(error => {
				console.log(error);
			});
			
			// $(".manage_fixed").fadeOut();
		},
		// 没有登录保存车辆管理
		noLoginBaocun(){
			
			if(this.nologogdefault.id){
				let lognam = this.nologogdefault.modelName;
				this.nologogdefault.modelName = lognam.join(" ");
				sessionStorage.setItem("nologogdefault",JSON.stringify(this.nologogdefault));
				this.cartToggle(0);
				this.$message({
						message: '保存成功!',
						type: 'success',
				});
			}else{
				this.$message({
						message: '请选择型号!',
						type: 'error',
				});
			}
			
		},
		/* 退出登录 */
		loginOut(){
			// 清除token
			var $token = this.$store.state.loginStore.login.token;

			this.$axios.post('/api2/portal/auth/user/logout',{token:$token}).then(res=>{
				console.log(res);
			}).catch(error=>{
				console.log(error);
			});
			window.localStorage.setItem("login",null);
			this.$router.push({path:"/sign/scancode"});
			console.log(QC.Login.check());
			if(QC.Login.check()){
				QC.Login.signOut()
			}
		},
		/* 获取车辆管理信息 */
		getCarReg(){
			this.$axios.post('/api2/portal/auth/user/carInfo/findMyCarByCondition',{
				current: 1,
				size: 1000
			}).then(res => {
				
				this.cartcont = res.data.data;
				let arr = this.cartcont;
				sessionStorage.removeItem("nologogdefault");
				for(let x of arr){
					
					if(x.isDefault==1){
						
						if(this.nologogdefault.denglui!=1){
							this.nologogdefault = x;
							sessionStorage.setItem("nologogdefault",JSON.stringify(x));
						}
						
						this.default1=x;
					}
					
				}
				if(sessionStorage.getItem("nologogdefault")){
					let nolo = JSON.parse(sessionStorage.getItem("nologogdefault"));
					if(nolo.denglui==1){
						var findid = arr.findIndex( it => {
							return it.brandParamId == nolo.id
						});
						console.log(findid);
					}
				}
				
			}).catch(error =>{
				console.log(error);
			});

		},
		addCart(){
			var that = this;
			function getBrandLogo(){
				return that.$axios.post('/api2/portal/product/carBrand/listCarBrandAll')
			}
			this.$axios.all([getBrandLogo()]).then(res => {
				// console.log(res);
				// 获取品牌logo
				this.plogo = res[0].data;
				
			}).catch(error =>{
				console.log(error);
			});
		},
		skipSgin(){
			if(window.innerWidth>1199){
				if(window.localStorage.getItem("login") == '' || window.localStorage.getItem("login") == null){
					this.$router.push({path:"/sign/scancode"});
				}else{
					this.$router.push({path:"/my/mymessage"});
				}
			}
		},
		b2pmouse(_in){
			this.imon=_in
		},
		navTo(pan,id,title){
			console.log(this.$route);
			if(this.$route.name!="Productlist"){
				this.$router.push({name: 'Productlist',params:{
					pan,id,title
				}});
			}else{
				this.$router.push({params:{
					pan,id,title
				}});
			}
		},
		getLocalStorageData(name){
			let data = localStorage.getItem(name);
			return data;
		},
		setLocalStorageData(name,obj){
			let data = typeof obj == 'string'?obj:JSON.stringify(obj);
			localStorage.setItem(name,data);
		}
	},
	watch: {
		shopfixed2:{
			handler(newValue,oldValue){
  				newValue.forEach(v => {
                    let leth = v.buyNum.length;
                    if(leth>8){
                        this.$message({
                            message: "最多输入八位数！",
                            type: 'error',
                        });
                        v.buyNum = Number(v.buyNum.substring(0,8));
                        return false;
                    }
                });
			},deep: true, // 深度监听
		}
	}
}

</script>

<style scoped>
	.fade-enter-active,.fade-leave-active{
		transition: opacity 0.5s ease;
	}
	.fade-enter,.fade-leave-to{
		opacity: 0;
	}
</style>