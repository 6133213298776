import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
require("./assets/js/mock/mock.js");

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import "./assets/css/iconfont.css";
import "./assets/css/style.css";

import setAxios from './setAxios.js';
setAxios();

Vue.prototype.$axios = axios;


/* 路由登录拦截 */
router.beforeEach((to,from,next)=>{
  store.commit('settoken',JSON.parse(localStorage.getItem('login')));
  if(to.meta.title){ //设置标题名称
    document.title = to.meta.title;
  }
  if(to.meta.impose){ //设置登录权限
    if(window.localStorage.getItem("login") == '' || window.localStorage.getItem("login") == null){
      next('/sign/scancode');
    }else{
      next();
    }
  }
  next();
});



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
