<template>
    <div class="account1">
        <form action="" class="form">
            <div class="item">
                <input type="text" v-model="form.tel" placeholder="手机号码" value="">
            </div>
            <div class="item code1">
                <input type="text" v-model="form.code" placeholder="验证码" value="">
                <a href="javascript:;" @click="codeSend()" class="yan">{{codesen}}</a>
            </div>
            <router-link to="/sign/passign" class="link">使用密码登录</router-link>
            <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                <span>登录账号</span>
                <i>
                    <img src="../../assets/images/jiantouyo1.png" >
                    <img src="../../assets/images/jiantouyo2.png" >
                </i>
            </button>
        </form>
        <!-- <div class="lo">
            没有账号？<router-link to="/register">去注册</router-link>
        </div> -->
    </div>
</template>

<script>
import { json } from 'mathjs';

export default {
    data(){
        return {
            form:{
                tel: "",
                code:""
            },
            codesen:"发送验证码",
            codeswitch: true,
        }
    },
    mounted(){
        // console.log(this.$store.state.loginStore.login);
    },
    methods:{
        telCode:function(){
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'error',
                });
                return false;
            }
            
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误',
                    type: 'error',
                });
                return false;
            }
            

        },
        formSubmit:function(){  //验证码登录
           if(!this.telCode()){
               return false;
           }
           if(this.form.code == ""){
                this.$message({
                    message: '验证码不能为空',
                    type: 'error',
                });
                return false;
           }
           this.$axios.post('/api2/portal/user/login',{
            phone: this.form.tel,
            verifyCode: this.form.code,
            type: 0
           }).then((res)=>{
                if(res.code == "000000"){
                    /* 登录设置token */
                    this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
                    window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
                }else{
                    this.$message({
                    message: res.message,
                    type: 'error'
                });
                }
           }).catch((error)=>{
                console.log(error);
                this.$message({
                    message: '接口出现异常',
                    type: 'error'
                });
           });
        },
        async codeSend(){  //验证码发送
            
            if(!this.telCode()){
               return false;
           }
           await this.$axios.post('/api2/portal/contact/sms/send',{phone:this.form.tel,type: "verifyCode"}).then(res=>{
                console.log(res);
                if(res.code=="000000"){
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                    this.codeswitch=true;
                }else{
                   this.$message({
                        message: '发送失败',
                        type: 'error'
                    });
                    this.codeswitch=false;
                }
            }).catch(error=>{
                console.log(error);
                this.$message({
                    message: '接口出现异常',
                    type: 'error'
                });
                this.codeswitch=false;
            });
           if(this.codeswitch){
               this.codeswitch=false;
               var ti = 60;
               var tiem;
               tiem = setInterval(()=>{
                   ti--;
                   if(ti<=0){
                       this.codesen = "重新发送";
                       this.codeswitch = true;
                       clearInterval(tiem);
                       return false;
                   }
                   this.codesen="("+ti+")";
               },1000);
           }
        }
    }
}
</script>
