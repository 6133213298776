<template>
    <div id="Sign1">
        <div class="hadheight"></div>
        <Header />
        <div class="f_resetpas resetpas-sec1 base80">
            <div class="basecont">
                <div class="cont">
                    <div class="tit basefont30">账号验证</div>
                    <form action="" class="form">
                        <div class="item ">
                            <input type="text" autocomplete="off" v-model="form.tel" placeholder="输入手机号" value="">
                            <div class="f_tishi" v-show="tishi1">
                                <i><img src="../../assets/images/gantanhaoimgfe1.png" alt=""></i><p>请输入正确手机号码</p>
                            </div>
                        </div>
                        <!-- <div class="drag"
                            ref="dragDiv">
                            <div class="drag_bg"></div>
                            <div class="drag_text">{{confirmWords}}</div>
                            <div ref="moveDiv"
                                @mousedown="mousedownFn($event)"
                                @touchstart="touchstartFn($event)"
                                :class="{'handler_ok_bg':confirmSuccess}"
                                class="handler handler_bg"
                                style="position: absolute;top: 0px;left: 0px;"></div>
                        </div> -->
                        <div class="item code1">
                            <input type="text" autocomplete="off" v-model="form.code" placeholder="验证码" value="">
                            <a href="javascript:;" @click="codeSend()" class="yan">{{codesen}}</a>
                        </div>
                        <div class="item ">
                            <input type="password" readonly  onfocus="this.removeAttribute('readonly')"  autocomplete="off" v-model="form.password" placeholder="输入新密码" value="">
                            <div class="f_tishi" v-show="tishi2">
                                <p>包含字母和数字</p>
                                <p>且长度为8-16个字符</p>
                            </div>
                        </div>
                        <div class="item ">
                            <input type="password" readonly  onfocus="this.removeAttribute('readonly')"  autocomplete="off" v-model="form.password2" placeholder="重复新密码" value="">
                        </div>
                        <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                            <span>确认设置</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </button>
                    </form>
                </div>
            </div>
        </div>
        <!-- 成功弹窗 -->
        <div class="resetpas_alt">
            <div class="bag" @click="afteFadeOut()"></div>
            <div class="cont">
                <a href="javascript:;" @click="afteFadeOut()" class="close" ><img src="../../assets/images/closeiconf1.png" alt=""></a>
                <div class="f_iocn">
                    <i><img src="../../assets/images/chenggongwfdagou1.png" alt=""></i>
                    <span>密码重置成功</span>
                </div>
                <div class="text">{{seh}}s后跳入登录页面</div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { MD5 } from 'crypto-js';
export default {
    name: "Resetpas",
    components:{
        Header,
        Footer,
    },
    data(){
        return {
            seh: 5, //重置密码成功后倒计时
            form:{
                tel: "",
                code:"",
                password: "",
                password2:"",
            },
            tishi1: false,
            tishi2: false,
            codesen:"发送验证码",
            codeswitch: true,
            tishi: false, //提示
             beginClientX: 0, /* 距离屏幕左端距离 */
            mouseMoveStata: false, /* 触发拖动状态  判断 */
            maxwidth: '', /* 拖动最大宽度，依据滑块宽度算出来的 */
            confirmWords: '', /* 滑块文字 */
            confirmSuccess: false, /* 验证成功判断 */
        }
    },
    computed:{
        
    },
    methods:{
         // MD5加密
        passwordMD5(mess){
            return MD5(mess).toString().toUpperCase();
        },
        afteFadeOut:function(){
            $(".resetpas_alt").fadeOut();
        },
        afteFadeIn:function(){
            $(".resetpas_alt").fadeIn();
            setInterval(()=>{
                this.seh--;
                if(this.seh<=0){
                    this.$router.push({path:"/sign/passign"});
                }
            },1000);
        },
        /* 表单提交 */
        formSubmit:function(){
            if(!this.telCode()){
               return false;
           }
        //    if(this.confirmSuccess==false){
        //        this.$message({
        //             message: '请滑动验证',
        //             type: 'error',
        //         });
        //         return false;
        //    }
           if(this.form.code==""){
                this.$message({
                    message: '验证码不能为空',
                    type: 'error',
                });
                return false;
           }
           var pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;//8到16位数字与字母组合
            if(this.form.password == ""){
                this.$message({
                    message: '密码不能为空',
                    type: 'error',
                });
                return false;
            }
            if(!pwdReg.test(this.form.password)){
                this.tishi2 = true;
                setTimeout(()=>{this.tishi2=false},2000);
                return false;
            }
            if(this.form.password!=this.form.password2){
                this.$message({
                    message: '两次密码不一致',
                    type: 'error',
                });
                return false;
            }
            this.$axios.post('/api2/portal/user/forgetPassword',{
                phone: this.form.tel,
                type: 0,
                verifyCode: this.form.code,
                password: this.passwordMD5(this.form.password),
                confirmPassword: this.passwordMD5(this.form.password2),
            }).then(res => {
                console.log(res);
                if(res.code=="000000"){
                    // this.afteFadeIn();
                     this.$message({
                        message: '保存成功!',
                        type: 'success',
                    });
                    this.$router.push({path: "/sign/passign"})
                }else{
                    this.$message({
                        message: res.message,
                        type: 'error',
                    });
                }
            }).catch(error => {
                console.log(error);
                this.$message({
                    message: '设置失败!',
                    type: 'error',
                });
            });
            
            
        },
        /* 手机验证 */
        telCode:function(){
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'error',
                });
                return false;
            }
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误',
                    type: 'error',
                });
                this.tishi = true;
                setTimeout(()=>{this.tishi=false},2000);
                return false;
            }
            
        },
        /* 发送接口 */
        codeSend:function(){
            if(!this.telCode()){
               return false;
           }
        //    if(this.confirmSuccess==false){
        //        this.$message({
        //             message: '请滑动验证',
        //             type: 'error',
        //         });
        //         return false;
        //    }
           this.$axios.post('/api2/portal/contact/sms/send',{
                phone: this.form.tel,
                type: "verifyCode",
           }).then(res => {
                console.log(res);
                if(res.code=="000000"){
                    this.$message({
                        message: '发送成功!',
                        type: 'success',
                    });
                    if(this.codeswitch){
                        this.codeswitch=false;
                        var ti = 60;
                        var tiem;
                        tiem = setInterval(()=>{
                            ti--;
                            if(ti<=0){
                                this.codesen = "重新发送";
                                this.codeswitch = true;
                                clearInterval(tiem);
                                return false;
                            }
                            this.codesen="("+ti+")";
                        },1000);
                    }
                }else{
                    this.$message({
                        message: '发送失败!',
                        type: 'error',
                    });
                }
           }).catch(error => {
                console.log(error);
                this.$message({
                    message: '接口异常!',
                    type: 'error',
                });
           });
           
        },
        mousedownFn: function (e) {
            if (!this.confirmSuccess) {
                e.preventDefault && e.preventDefault() // 阻止文字选中等 浏览器默认事件
                this.mouseMoveStata = true
                console.log(e.clientX);
                this.beginClientX = e.clientX
            }
        }, // mousedoen 事件
        
        successFunction () {
            this.confirmSuccess = true
            this.confirmWords = '验证通过'
            if (window.addEventListener) {
                document.getElementsByTagName('html')[0].removeEventListener('mousemove', this.mouseMoveFn)
                document.getElementsByTagName('html')[0].removeEventListener('mouseup', this.moseUpFn)
            } else {
                document.getElementsByTagName('html')[0].removeEventListener('mouseup', () => { })
            }
            document.getElementsByClassName('drag_text')[0].style.color = '#fff'
            document.getElementsByClassName('handler')[0].style.left = this.maxwidth + 'px'
            document.getElementsByClassName('drag_bg')[0].style.width = this.maxwidth + 'px'
        }, // 验证成功函数
        mouseMoveFn (e) {
            if (this.mouseMoveStata) {
                var width = e.clientX - this.beginClientX;
                if(window.innerWidth<1199){
                    width = e.touches[0].clientX - this.beginClientX;
                }
                console.log(width);
                if (width > 0 && width <= this.maxwidth) {
                document.getElementsByClassName('handler')[0].style.left = width + 'px'
                document.getElementsByClassName('drag_bg')[0].style.width = width + 'px'
                } else if (width > this.maxwidth) {
                this.successFunction()
                }
            }
        }, // mousemove事件
        moseUpFn (e) {
            
            this.mouseMoveStata = false
            var width = e.clientX - this.beginClientX;
            if(window.innerWidth<1199){
                width = e.touches[0].clientX - this.beginClientX;
            }
            console.log(width);
            if (width < this.maxwidth) {
                document.getElementsByClassName('handler')[0].style.left = 0 + 'px'
                document.getElementsByClassName('drag_bg')[0].style.width = 0 + 'px'
            }
        }, // mouseup事件
        touchstartFn:function(e){
            if (!this.confirmSuccess) {
                e.preventDefault && e.preventDefault() // 阻止文字选中等 浏览器默认事件
                 var touch = e.touches[0]; //获取第一个触点  
                var x = touch.pageX; //页面触点X坐标  
                var y = Number(touch.pageY); //页面触点Y坐标  
                this.mouseMoveStata = true
                this.beginClientX = x
                
            }
        },
    },
    mounted () {
        // this.maxwidth = this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
        // if(window.innerWidth<1199){
        //     document.getElementsByTagName('html')[0].addEventListener('touchmove', this.mouseMoveFn);
        //     document.getElementsByTagName('html')[0].addEventListener('touchend', this.moseUpFn);
        // }else{
        //     document.getElementsByTagName('html')[0].addEventListener('mousemove', this.mouseMoveFn);
        //     document.getElementsByTagName('html')[0].addEventListener('mouseup', this.moseUpFn);
        // }
        
    }
}
</script>

<style scoped>
.drag {
  position: relative;
  background-color: #ececec;
  width: 100%;
  height: 60px;
  border: 1px solid #e2e4e8;
  line-height: 60px;
  text-align: center;
  margin-bottom: 20px;
}
.handler {
  width: 80px;
  height: 32px;
  border-right: 1px solid #e2e4e8;
  cursor: move;
}
.handler_bg {
    height: 100%;
  background: #fff
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==')
    no-repeat center;
}
.handler_ok_bg {
  background: #fff
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==')
    no-repeat center;
}
.drag_bg {
  background-color: #7ac23c;
  height: 100%;
  width: 0px;
}
.drag_text {
  position: absolute;
  top: 0px;
  width: 100%;
  text-align: center;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
}
@media  (max-width: 1440px) {
    .handler{
        width: 70px;
    }
    .drag{
        line-height: 52px;
        height: 52px;
        margin-bottom: 12px;
    }
}
@media  (max-width: 991px) {
    .handler{
        width: 60px;
    }
    .drag{
        line-height: 42px;
        height: 42px;
        margin-bottom: 12px;
    }
}


</style>
