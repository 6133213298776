<template>
    <div class="scancode_alt">
            <div class="bag" @click="close()"></div>
            <div class="cont">
                <div class="f_close" @click="close()">
                    <img src="../../assets/images/closeiconf1.png" alt="">
                </div>
                <div class="fz_tit basefont30">
                    绑定手机号码
                </div>
                <form action="" class="form">
                    <div class="item">
                        <input type="text" v-model="form.tel" placeholder="手机号码" value="">
                    </div>
                    <div class="item code1">
                        <input type="text" v-model="form.code" placeholder="验证码" value="">
                        <a href="javascript:;" @click="codeSend()" class="yan">{{codesen}}</a>
                    </div>
                    <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                        <span>绑定</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </button>
                </form>
            </div>
        </div>
</template>

<script>


export default{
    props:["axiosurl","params","toggle"],
    data(){
        return {
             form:{
                tel: "",
                code:"",
                openid: "",
                unionid: "",
            },
            codesen:"发送验证码",
            codeswitch: true,
        }
    },
    mounted(){
        // this.form.openid = this.params.openid;
    },
    watch:{
        toggle(afterVla,beforeVla){
            
            if(afterVla){
                
                this.form.openid = this.params.openid;
                this.form.unionid = this.params.unionid;
            }
        }
    },
    methods:{
        telCode:function(){ //手机号码判断
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'error',
                });
                return false;
            }
            
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误',
                    type: 'error',
                });
                return false;
            }
        },
        async codeSend(){  //验证码发送
            
            if(!this.telCode()){
               return false;
           }
           await this.$axios.post('/api2/portal/contact/sms/send',{phone:this.form.tel,type: "verifyCode"}).then(res=>{
                if(res.code=="000000"){
                    this.$message({
                        message: '发送成功',
                        type: 'success'
                    });
                    this.codeswitch=true;
                }else{
                   this.$message({
                        message: '发送失败',
                        type: 'error'
                    });
                    this.codeswitch=false;
                }
            }).catch(error=>{
                console.log(error);
                this.$message({
                    message: '接口出现异常',
                    type: 'error'
                });
                this.codeswitch=false;
            });
           if(this.codeswitch){
               this.codeswitch=false;
               var ti = 60;
               var tiem;
               tiem = setInterval(()=>{
                   ti--;
                   if(ti<=0){
                       this.codesen = "重新发送";
                       this.codeswitch = true;
                       clearInterval(tiem);
                       return false;
                   }
                   this.codesen="("+ti+")";
               },1000);
           }
        },
        formSubmit(){  //电话号码绑定
            console.log(this.form);
            this.$axios.post(this.axiosurl,{
                openid: this.form.openid,
                phone: this.form.tel,
                verifyCode: this.form.code,
                unionid: this.form.unionid,
            }).then( res => {  //接口触发成功

                console.log(res);
                if(res.code == "000000"){
                    /* 登录设置token */
                    this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
                    window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
                    
                }else{  //登录错误
                    this.$message({
                        message: res.message,
                        type: 'error'
                    });
                }
    
            }).catch(error => { //接口错误的时候执行
                console.log(error);
            });
        },
        close(){
            // this.toggle = false;
            this.$emit("toghide",false);
        }
    }
}
</script>
