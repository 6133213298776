<template>
    <div class="scancode">

        <div class="scancode_img">
            <div class="er" id="login_container"> </div>
            <img src="../../assets/images/iocnimg1.png" class="f_img1" alt="">
        </div>
        <!-- <div class="fz_text basefont24">微信登录</div> -->
        <div class="zhanghao">
            <span>{{scaTitle}}</span>
            <div class="f_jut">
                <a href="javascript:;" @click="tz" class="li">
                    <img src="../../assets/images/dengluiconimgf1.png" alt="">
                </a>
                <!-- <div class="li" ref="qqLoginBtn" id="qqLoginBtn"></div> -->
                
                <!-- <a href="javascript:;" @click="qqClickLogin" class="li"><img src="../../assets/images/dengluiconimgf1.png" alt=""></a> -->
                <a href="javascript:;"  class="li"><img src="../../assets/images/dengluiconimgf2.png" alt=""></a>
                <!-- <a href=""><img src="../../assets/images/dengluiconimgf3.png" alt=""></a> -->
            </div>
        </div>
        <!-- <div class="lo">
            没有账号？<router-link to="/register">去注册</router-link>
        </div> -->
        <!-- 手机号码绑定 -->
        <transition name="fade">
            <BingPhoneAlt 
                :params="messgage" 
                :axiosurl="url" 
                :toggle = "kai"
                @toghide = "toghide"
                v-show="kai"
                >
            </BingPhoneAlt>
        </transition>
    </div>
</template> 


<script>
    import BingPhoneAlt from '@/components/wxqqlogin/Bing_phone_Alt.vue';
    // import QC from 'qc';
    // import api from '@api/weChat/index';
    // import { mapState } from 'vuex';  //computed getters, methods, and state for this page.  Also, import the store from
    export default {
        components:{
            BingPhoneAlt,
        },
        data(){
            return {
                scaTitle: "第三方账户登录",
                messgage: {},
                qqtelMexxage: {},
                kai: false, 
                url: "",
                loginkai: true,
            }
        },
        
        mounted(){
            


            /* 微信登录 */
             var obj = new WxLogin({
                self_redirect:false,
                id:"login_container", 
                appid: "wxfdb11b9a21a23115", 
                scope: "snsapi_login", 
                redirect_uri: "https://www.habitos.cn/sign/scancode",
                state: "097c816c4829bca78080a81a7ab4f7e9",
                style: "black",   // black 或者 white
                href: 'https://demo.growinglab.com.cn/wxcss/wx.css',
            });

            this.wxClickLogin();


            /* 初始化qq登录 */
            // QC.Login({
            //     btnId: 'qqLoginBtn'
            // },function(reqData, opts){
            //     // console.log(reqData,opts); //查看返回数据 
            // });
            let time;
            let that = this;
            // this.$refs["qqLoginBtn"].onclick=function(){
            //     if(time){
            //         that.loginkai=true;
            //         clearInterval(time);
            //     }
            //     time=setInterval(function(){
            //         if(QC.Login.check()){
            //             clearInterval(time);
            //             QC.Login.getMe((openId, accessToken) => {
            //                 if(that.loginkai){
            //                     that.loginkai=false
            //                     that.qqClickLogin(accessToken,1);
            //                 }
            //             });
            //         }
                    
            //     },2000);
            // };
            if(QC.Login.check()){
                QC.Login.getMe((openId, accessToken) => {
                    if(accessToken){
                        that.qqClickLogin(accessToken,1);
                    }
                });
            }
            
            
            
          
            // if(this.$route.hash){  //这个是当前页面登录
                
            //     let datahash = this.$route.hash.substring(1).split("&");
            //     datahash.forEach( (it,index) => {
            //         var _in = it.indexOf("=");
            //         if(_in!=-1){
            //             datahash[index] =  it.substring(_in+1);
            //         }
                    
            //     });
            //     if(datahash.length>0){
                   
            //         this.qqClickLogin(datahash[0]);
            //         // window.close();
            //     }
            //     // alert(JSON.stringify(datahash));
                
            // }
            
        },
        computed:{
            // ...mapState(['login']),
        },
        methods:{
          tz(){
            // var urldz = "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=102065649&response_type=token&scope=all&redirect_uri=" + window.location.origin+"/qqLogin";
            // window.location.href = urldz;
    
            console.log(QC);
            QC.Login.showPopup({
                appId: '102065649',
                redirectURI: 'http://www.habitos.cn/qqLogin',
                scope: 'all',
                onSuccess: function (qcResult) {
                // 用户登录成功，可以使用qcResult获取到用户的信息
                    var accessToken = qcResult.accessToken;
                    var openid = qcResult.openId;
                // 其他操作...
                },
                onFail: function (qcResult) {
                // 用户登录失败的处理
                    console.log(qcResult);
                }
            });
          },
          qqClickLogin(accessToken,jud){
                // if(QC.Login.check()){
                //     QC.Login.signOut();
                // }
                // return false;
                // QC.Login.showPopup({
                //       appId:"102065649",
                //       redirectURI:"http://www.habitos.cn/qqLogin"
                // });
                
                this.$axios.post('/api2/portal/user/qqLoginForPC',{accessToken: accessToken}).then((res)=>{
                    if(res.code == "000000"){
                        // if(jud==undefined){
                        //     window.close();
                        //     return false;
                        // }
                        /* 登录设置token */
                        if(res.data.bindFlag == 0){  //判断qq是否绑定手机号码
                            this.messgage = res.data;  //参数
                            this.url = "/api2/portal/user/qqBindPhoneForPC"; //qq绑定的接口
                            //显示弹窗
                            this.$nextTick( () => {
                                this.togShow(); 
                            });
                        }else{  //绑定后登录
                            this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
                            window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
                            this.$message({
                                message: '登录成功',
                                type: 'success'
                            });
                            this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
                        }
                        
                    }else{
                        this.$message({
                            message: res.message,
                            type: 'error'
                        });
                    }
            }).catch((error)=>{
                    console.log(error);
                    this.$message({
                        message: '接口出现异常',
                        type: 'error'
                    });
            });
          },
          wxClickLogin(){
            // 微信登录
            if(this.$route.query.code){
                // console.log(this.$route.query);
                    this.$axios.post('/api2/portal/user/wxLoginForPC',{code: this.$route.query.code}).then((res)=>{
                            // console.log(res);
                            if(res.code == "000000"){
                                /* 登录设置token */
                                if(res.data.bindFlag == 0){  //判断微信是否绑定手机号码
                                    this.messgage = res.data;  //参数
                                    this.url = "/api2/portal/user/wxBindPhoneForPC"; //微信绑定的接口
                                    //显示弹窗
                                    this.$nextTick( () => {
                                        this.togShow(); 
                                    });
                                }else{  //绑定后登录
                                    this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
                                    window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
                                    this.$message({
                                        message: '登录成功',
                                        type: 'success'
                                    });
                                    this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
                                }
                                
                            }else{  //登录错误
                                this.$message({
                                    message: res.message,
                                    type: 'error'
                                });
                            }
                    }).catch((error)=>{ //接口出现异常
                            console.log(error);
                            this.$message({
                                message: '接口出现异常',
                                type: 'error'
                            });
                    });
            }

            
        //     this.$axios.post('/api2/portal/user/login',{accessToken: 3}).then((res)=>{
        //         if(res.code == "000000"){
        //             /* 登录设置token */
        //             this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
        //             window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
        //             this.$message({
        //                 message: '登录成功',
        //                 type: 'success'
        //             });
        //             this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
        //         }else{
        //             this.$message({
        //                 message: res.message,
        //                 type: 'error'
        //             });
        //         }
        //    }).catch((error)=>{
        //         console.log(error);
        //         this.$message({
        //             message: '接口出现异常',
        //             type: 'error'
        //         });
        //    });
          },
          toLogin(){
                //以下为按钮点击事件的逻辑。注意这里要重新打开窗口
                //否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
                var A=window.open("/oauth/index.php","TencentLogin","width=450,height=320,menubar=0,scrollbars=1,resizable=1,status=1,titlebar=0,toolbar=0,location=1");
          },
          toghide(_val){ //弹窗隐藏
            this.kai = _val;
            if(QC.Login.check()){
                QC.Login.signOut()
            }
          },
          togShow(){ //弹窗显示
            this.kai = true;
          },
          qqClick(){
           
          }
        },
        // beforeRouteEnter(to,from,next){
        //     if(to.hash  == ""){
        //         if(QC.Login.check()){
        //             QC.Login.signOut()
        //         }
        //     }
        //    next();
            
        // },
    }
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>