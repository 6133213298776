import Vue from 'vue';
import VueRouter from 'vue-router';
import loginRegi from './loginRegi/loginRegi';  //登录路由
import product from './product/product';  //产品路由
import brand from './brand/brand';  //品牌路由
import personal from './personal/personal';  //个人中心路由
import websiteinfo from './websiteinfo/websiteinfo';  //网站地图、免责声明的路由
import ordershoop from './ordershoop/ordershoop';  //网站地图、免责声明的路由
Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home'),
    meta:{
      title:"HabitOS车改社区_HabitOS_HabitOS车改_玩车-HabitOS车改_改装-HabitOS车改社区"
    }
  },
  {
    path: "",
    component: () => import(/* webpackChunkName: "about" */ '../views/about'),
    children:[
      {
        path: '/about',
        name: 'About',
        component: () => import('../views/about/About.vue'),
        meta:{
          title: "关于Habitos",
        },
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/about/Contact.vue'),
        meta:{
          title: "联系我们",
        },
      }
    ]
  },
  {
    path: "/case",
    name: "Case",
    component: () => import('../views/case/'),
    children:[
      {
        path: "",
        component: () => import('../views/case/Case.vue'),
        meta:{
          title: "品牌案例"
        },
      },
      {
        path: "caseinfo",
        name: "Caseinfo",
        component: () => import('../views/case/CaseInfo.vue')
      }
    ]
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import(/* webpackChunkName: "about" */ '../views/cooperation/Cooperation.vue'),
    meta: {
      title: "商务合作"
    }
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import(/* webpackChunkName: "about" */ '../views/faq/Faq.vue'),
    meta:{
      title: "常见问题"
    }
  },
  {
    path: "/search",
    name: "Search",
    component: ()=>import('../views/Search.vue'),
    // beforeEnter: (to, from, next) => {
    //   if(to.query.title){
    //     document.title = to.query.title
    //     next();
    //   }
    // },
    
  },
  {
    path: "*",
    component: ()=>import('../components/404.vue'),
  }
].concat(loginRegi,product,brand,personal,websiteinfo,ordershoop);

const router = new VueRouter({
  mode: 'history',
  // mode: "hash",
  // base: './',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
      return {x: 0, y: 0};
  }
});

export default router;
