
export default [
  {
    path: "/my",
    name: "My",
    component: ()=>import('../../views/personal/index'),
    children: [
      {
        path: "mymessage",
        name: "Mymessage",
        component: ()=>import('../../views/personal/My_message.vue'),
        meta:{
          title: "账户信息",
          impose: true
        }
      },
      {
        path: "mycollection",
        name: "Mycollection",
        component: ()=>import("../../views/personal/My_collection.vue"),
        meta: {
          title: "我的收藏",
          impose: true
        }
      },
      {
        path: "mysecurity",
        name: "Mysecurity",
        component: ()=>import("../../views/personal/My_security.vue"),
        meta: {
          title: "账户与安全",
          impose: true
        }
      },
      {
        path: "mynotice",
        name: "Mynotice",
        component: ()=>import("../../views/personal/My_notice.vue"),
        meta: {
          title: "消息通知",
          impose: true
        }
      },
      {
        path: "myaddress",
        name: "Myaddress",
        component: ()=>import("../../views/personal/My_address.vue"),
        meta: {
          title: "收货地址",
          impose: true
        }
      },
      {
        path: "myorder",
        name: "Myorder",
        component: ()=>import("../../views/personal/My_order.vue"),
        meta: {
          title: "订单中心",
          impose: true
        }
      }
    ],
    meta:{
      impose: true
    }
  },
  {
    path: "/mynoticeinfo",
    name: "Mynoticeinfo",
    component: ()=>import("../../views/personal/My_noticeinfo.vue"),
    meta:{
      impose: true
    }
  },
]