
<template>
    <div class="account1">
        <form action="" class="form">
            <div class="item">
                <input type="text" v-model="form.tel" placeholder="手机号码" value="">
            </div>
            <div class="item ">
                <input type="password" v-model="form.password" placeholder="密码" value="">
            </div>
            <div class="fw_jut">
                <router-link to="/sign/account" class="link">使用手机验证码登录</router-link>
                <router-link to="/resetpas" class="link2">忘记密码？</router-link>
            </div>
            <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                <span>登录账号</span>
                <i>
                    <img src="../../assets/images/jiantouyo1.png" >
                    <img src="../../assets/images/jiantouyo2.png" >
                </i>
            </button>
        </form>
        <!-- <div class="lo">
            没有账号？<router-link to="/register">去注册</router-link>
        </div> -->
    </div>
</template>
<script>
import { MD5 } from 'crypto-js';
export default {
    data(){
        return {
            form:{
                tel: "",
                password:""
            },
        }
    },
    created(){
        $(function(){
            $(".sign_nav .item").eq(1).addClass("on");
        });
    },
    mounted(){
      console.log(this.passwordMD5(500));
    },
    computed:{
        
    },
    methods:{
        // MD5加密
        passwordMD5(mess){
            return MD5(mess).toString().toUpperCase();
        },
        telCode:function(){
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'error',
                });
                return false;
            }
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误',
                    type: 'error',
                });
                return false; 
            }
        },
        formSubmit:function(){
        //     if(!this.telCode()){
        //        return false;
        //    }
        //    if(this.form.password == ""){
        //         this.$message({
        //             message: '密码不能为空',
        //             type: 'error',
        //         });
        //    }
          this.$axios.post('/api2/portal/user/login',{
            phone: this.form.tel,
            password: this.passwordMD5(this.form.password),
            type: 1
           }).then((res)=>{
                console.log(res);
                if(res.code == "000000"){
                    /* 登录设置token */
                    this.$store.commit('settoken',res.data);  //在vuex中loginStore模块设置
                    window.localStorage.setItem('login',JSON.stringify(res.data));  //localStorage中设置
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });
                    this.$router.replace({path:'/my/mymessage'});  //跳转信息页面
                }else{
                    this.$message({
                    message: res.message,
                    type: 'error'
                });
                }
           }).catch((error)=>{
                console.log(error);
                this.$message({
                    message: '接口出现异常',
                    type: 'error'
                });
           });
        //    this.$axios.post('/fapi/login',this.form).then(res=>{
        //         let result = res; 
        //         console.log(res);
        //         if(result.code=='0'){ 
        //             /* 登录设置token */
        //             this.$store.commit('settoken',result.token);  //在vuex中loginStore模块设置
        //             window.localStorage.setItem('login',result.token);  //localStorage中设置
        //             //判断路由是否带参，带参就去到重定向参数地址，否则就去首页
        //             if(this.$route.query.redirect){
        //                  this.$router.replace({path:this.$route.query.redirect});
        //             }else{
        //                 this.$router.replace({path:'/my/mymessage'});
        //             }
        //         }else{
        //              this.$message(result.message);
        //         }
        //    }).catch(function(error){
        //        console.log(error);
        //    });
                
        }
    }
}
</script>