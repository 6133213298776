import Vue from 'vue';
import Vuex from 'vuex';
//登录的仓库
import loginStore from './loginStore/loginStore';
/* 购物车 */
import Cart from './Cart/Cart';
/* 账户信息 */
import UserInfo from './UserInfo/UserInfo';
/* 收货地址 */
import Address from './Address/Address';
Vue.use(Vuex); 



let store = new Vuex.Store({
  state:{
    conten: 100,
    erimgfz: '',
  },
  modules: {
    loginStore,
    Cart,
    UserInfo,
    Address
  },
});
//监听每次调用mutations的时候,都会进这个方法，然后我们可以做一些自己想做的处理
// store.subscribe((mutations,state)=>{
//   localStorage.setItem('cartarry',JSON.stringify(state.Cart.cartarry)); //购物车储存
// })

export default store;
