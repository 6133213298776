<template>
  <router-view />
</template>



<style scoped>
  
</style>
<script>
  export default{
    data(){
      return {
        
      }
    },
    methods:{
     
    }
  }
</script>
