/* 用户信息储存 */
const UserInfo = {
  state: {
    // userConten: localStorage.getItem('userConten')!=""?JSON.parse(localStorage.getItem('userConten')):{
    //   id: "",
    //   nickName: "",
    //   realName: "",
    //   userAge:"",
    //   userSex:"",
    //   userPhone:"",
    //   authenticationStatus:"",
    //   birthday:"", 
    //   headerImg:"",
    //   userEmail:"",
    //   introduction:"",
    //   homePage:"",
    //   sort:"",
    //   status:"",
    // },
    accountMessage:[],
    userConten:{
        id: "",
        nickName: "",
        realName: "",
        userAge:"",
        userSex:"",
        userPhone:"",
        authenticationStatus:"",
        birthday:"", 
        headerImg:"",
        userEmail:"",
        introduction:"",
        homePage:"",
        sort:"",
        status:"",
      }
  },
  mutations:{
    
  },
  actions: {
  },
  getters:{
    /* 获取所有账户信息 设置默认用户名称、性别等 */
   getuserConten(state){
    if(state.userConten){
      if(state.userConten.nickName==null){
        state.userConten.nickName="用户158038ejs/昵称";
      }
      if(state.userConten.userSex==null){
        state.userConten.userSex="男"
      }
    }
    return state.userConten;
   }
  }
};

export default UserInfo;