
export default [
    {
        path: "/product",
        name: "Product",
        component: () => import(/* webpackChunkName: "about" */ '../../views/product/'),
        meta:{
          title: "P2B"
        },
        children:[
          {
            path: "",
            component: () => import('../../views/product/Product.vue'),
          },
          {
            path: "productlist/:pan/:id/:title",
            name: "Productlist",
            component: () => import(/* webpackChunkName: "about" */ '../../views/product/Product_list.vue'),
          },
          {
            path: "productlistinfo/:id/:pan",
            name: "Productlistinfo",
            component: () => import(/* webpackChunkName: "about" */ '../../views/product/Product_listinfo.vue'),
          },
        ]
      },
]