import Scancode from '../../views/sign/Scancode.vue';
import Register from '../../views/sign/Register.vue';
import Account from '../../views/sign/Account.vue';
import Passign from '../../views/sign/Passign.vue';
import Resetpas from '../../views/sign/Resetpas.vue';
export default[
  {
    path: "/sign",
    name: "Sign",
    component: ()=> import('../../views/sign/Sign.vue'),
    meta: {
      title: "登录",
      yanzheng: true
    },
    children:[
      {
        path: 'scancode',
        name: 'Scancode',
        component: Scancode,
        meta:{
          title: "扫码登录"
        }
      },
      
      {
        path: 'account',
        name: 'Account',
        component: Account,
        meta:{
          title: "手机验证登录"
        }
      },
      {
        path: 'passign',
        name: 'Passign',
        component: Passign,
        meta:{
          title: "密码登录"
        }
      }
    ]
  },
  {
    path: '/qqLogin',
    name: 'QqLogin',
    redirect: (to, from) => {
      
    
      return {path: "/sign/scancode", query:to.query}
    },

    meta:{
      title: "扫码登录"
    }
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "注册"
    },
    component: Register
  },
  {
    path: "/resetpas",
    name: "Resetpas",
    meta: {
      title: "账号验证"
    },
    component: Resetpas
  },
  {
    path: "/resetpas2",
    name: "Resetpas2",
    meta: {
      title: "重置密码"
    },
    component: ()=>import('../../views/sign/Resetpas2.vue')
  }
]