<template>
    <div id="Sign1">
        <div class="hadheight"></div>
        <Header />
        <div class="register-sec1 base120">
            <div class="basecont">
                <div class="cont">
                    <div class="tit basefont30">账号注册</div>
                    <form action="" class="form">
                        <div class="item">
                            <input type="text" v-model="form.tel" placeholder="手机号码" value="">
                        </div>
                        <div class="item code1">
                            <input type="text" v-model="form.code" placeholder="验证码" value="">
                            <a href="javascript:;" @click="codeSend()" class="yan">{{codesen}}</a>
                        </div>
                        <div class="item pas1">
                            <input :type="leixin" v-model="form.password" placeholder="密码" value="">
                            <a href="javascript:;" @click="pas1()" :class="{f_icon: true,on: pasiw}">
                                <img src="../../assets/images/passwordiccon1.png" alt="">
                                <img src="../../assets/images/passwordiccon1-1.png" alt="">
                            </a>
                        </div>
                        <div class="item pas1">
                            <input :type="leixin2" v-model="form.password2" placeholder="确认密码" value="">
                            <a href="javascript:;" @click="pas2()" :class="{f_icon: true,on: pasiw2}">
                                <img src="../../assets/images/passwordiccon1.png" alt="">
                                <img src="../../assets/images/passwordiccon1-1.png" alt="">
                            </a>
                        </div>
                        <div class="f_jut">
                            <div class="name">快速注册:</div>
                            <div class="nei">
                                <a href="javascript:;">
                                    <img src="../../assets/images/weiiconimgf1.png" alt="">
                                </a>
                                <a href="javascript:;">
                                    <img src="../../assets/images/weiiconimgf2.png" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="zhengce">
                            <label><input type="checkbox" v-model="form.zhengce" true-value="yes" false-value="no"><span></span>同意用户
                            <router-link :to="{path: '/policy2',query: {title:'注册协议'}}">注册协议</router-link>和
                            <router-link :to="{path: '/policy',query: {title:'隐私条款'}}">隐私条款</router-link></label>
                        </div>
                        <button type="submit" @click.prevent="formSubmit()" class="hm-but f_sub">
                            <span>注册账号</span>
                            <i>
                                <img src="../../assets/images/jiantouyo1.png" >
                                <img src="../../assets/images/jiantouyo2.png" >
                            </i>
                        </button>
                        <div class="f_text">
                            如果您有账户请<router-link to="/sign/scancode">登录</router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { MD5 } from 'crypto-js';
export default {
    name: "Register",
    components:{
        Header,
        Footer
    },
    data(){
        return {
            form:{
                tel: "",
                code:"",
                password: "",
                password2: "",
                zhengce: "no"
            },
            codesen:"发送验证码",
            codeswitch: true,
            leixin: "password",
            pasiw: false,
            leixin2: "password",
            pasiw2: false
        }
    },
    methods:{
        // MD5加密
        passwordMD5(mess){
            return MD5(mess).toString().toUpperCase();
        },
        telCode:function(){
            var re = /^1\d{10}$/;
            if(this.form.tel==""){
                this.$message({
                    message: '手机号码不能为空',
                    type: 'warning',
                });
                return false;
            }
            if(re.test(this.form.tel)){
                return true;
            }else{
                this.$message({
                    message: '手机号码输入错误',
                    type: 'warning',
                });
                return false;
            }
        },
        formSubmit:function(){
           if(!this.telCode()){
               return false;
           }
           if(this.form.code == ""){
                this.$message({
                    message: '验证码不能为空',
                    type: 'warning',
                });
                return false;
           }
           if(this.form.password==""){
                this.$message({
                    message: '密码不能为空',
                    type: 'warning',
                });
                return false;
           }
           if(this.form.password==""){
                this.$message({
                    message: '密码不能为空',
                    type: 'warning',
                });
                return false;
           }
           var pwdReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;//8到16位数字与字母组合
            if(!pwdReg.test(this.form.password)){
                this.$message({
                    message: '包含字母和数字，且长度为8-16个字符',
                    type: 'warning',
                });
                return false;
            }
            if(this.form.password!=this.form.password2){
                this.$message({
                    message: '两次密码不一致',
                    type: 'warning',
                });
                return false;
            }
            if(this.form.zhengce=="no"){
                this.$message({
                    message: '请查看协议和隐私政策',
                    type: 'warning',
                });
                return false;
            }
           
        },
        codeSend:function(){
            if(!this.telCode()){
               return false;
           }
           if(this.codeswitch){
               this.codeswitch=false;
               var ti = 60;
               var tiem;
               tiem = setInterval(()=>{
                   ti--;
                   if(ti<=0){
                       this.codesen = "重新发送";
                       this.codeswitch = true;
                       clearInterval(tiem);
                       return false;
                   }
                   this.codesen="("+ti+")";
               },1000);
           }
        },
        pas1:function(){
         this.leixin=="password"?this.leixin="text":this.leixin="password";
         this.pasiw = !this.pasiw;
        },
        pas2:function(){
         this.leixin2=="password"?this.leixin2="text":this.leixin2="password";
         this.pasiw2 = !this.pasiw2;
        }
    }
}
</script>
