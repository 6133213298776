
export default [
  // {
  //   path: "/confirmorder",
  //   name: 'Confirmorder',
  //   component: ()=>import('../../views/payment/Confirmorder.vue'),
  //   meta:{
  //     title: "提交订单"
  //   }
  // },
  {
    path: "/orderdet",
    name: 'Orderdet',
    component: ()=>import('../../views/payment/Orderdet.vue'),
    meta:{
      title: "订单详情"
    },
    
  },
  {
    path: "/logistics",
    name: 'Logistics',
    component: ()=>import('../../views/payment/Logistics.vue'),
    meta:{
      title: "查看物流"
    }
  },
  {
    path: "/payment",
    name: 'Payment',
    component: ()=>import('../../views/payment/Payment.vue'),
    meta:{
      title: "订单支付"
    }
  },{
    path: "/sales",
    name: 'Sales',
    component: ()=>import('../../views/payment/Sales.vue'),
    meta:{
      title: "申请退货"
    }
  },{
    path: "/salesprogress/:id",
    name: 'Salesprogress',
    component: ()=>import('../../views/payment/Sales_progress.vue'),
    meta:{
      title: "退货进度"
    }
  },{
    path: "/invoice/:id",
    name: 'Invoice',
    component: ()=>import('../../views/payment/invoice.vue'),
    meta:{
      title: "申请开票"
    }
  },{
    path: "/complete",
    name: "Complete",
    component: ()=>import('../../views/payment/Complete.vue'),
    meta:{
      title: "支付成功"
    }
  },
  {
    path: "/shoppingcart1",
    name: "Shoppingcart1",
    component: ()=>import('../../views/payment/Shoppingcart1.vue'),
    meta:{
      title: "未登录-购物车"
    }
  },
  {
    path: "/shoppingcart2",
    name: "Shoppingcart2",
    component: ()=>import('../../views/payment/Shoppingcart2.vue'),
    meta:{
      title: "购物车",
      impose: true
    }
  },
]