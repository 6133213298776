<template>
    <div id="footer1">
        <!-- 底部 -->
	<footer class="fotbox">
		 <!-- <div class="fotbox-sec1">
			 <div class="basecont">
				 <div class="cont">
					<div class="f_item lt">
						 <div class="f_tit">
							 <div class="tit">
								 Contact Us
							 </div>
							 <span></span>
						 </div>
						 <div class="f_text">
							 <p v-for="(item,index) in contact" :key="index">{{item}}</p>
						 </div>
					</div>
					<div class="f_item f_nei">
						 <div class="f_tit">
							 <div class="tit">
								 Social Media
							 </div>
							 <span></span>
						 </div>
						 <div class="f_fen">
							 <a v-for="(item,index) in media" :key="index" :href="item.url" target="_blank" rel="nofollow">
								 <img :src="item.src" >
							 </a>
						 </div>
					</div>
					<div class="f_item gt">
						 <div class="f_tit">
							 <div class="tit">
								 Public
							 </div>
						 </div>
						 <div class="er">
							 <img :src="er" >
						 </div>
					</div>
				 </div>
			 </div>
		 </div> -->
		 <div class="fotbox-sec2">
			 <div class="basecont">
				 <div class="cont">
					 <div class="lt">
						 <p>{{fot2.copyright}}</p>
					 </div>
					 <div class="gt">
						<router-link to="/policy">
							隐私政策
						</router-link>
						<router-link to="/policy2">
							免责声明
						</router-link>
						<router-link to="/webmap">
							网站地图
						</router-link>
						 <a href="https://beian.miit.gov.cn/?spm=a313x.7781069.1998910419.39#/Integrated/index" target="_blank" rel="nofollow">{{fot2.icp}}</a>
					 </div>
				 </div>
			 </div>
		 </div>
	</footer>
	<!-- End -->
	
		<!-- 视频弹窗 -->
		<div id="hi-video-pop">
				<div href="javascript:;" class="hi-close"></div>
				<video src="" loop controls preload="none"></video>
		</div>
		<transition name="fade">
      <div class="proinfoAlt">
          <div class="bag" @click="proaltHide"></div>
          <div class="fz_cont">
            <div class="close" @click="proaltHide">
              <img src="../assets/images/closeicon1.png" alt="">
            </div>
            <div class="tit basefont24">
              去APP购买
            </div>
            <div class="text">抱歉，目前只支持在APP下单购买。</div>
            <div class="img">
              <img :src="erimgfz" alt="">
            </div>
          </div>
      </div>
    </transition>
	
    </div>
</template>

<script>
// 在单独构建的版本中辅助函数为 Vuex.mapState
import { mapState } from 'vuex';
export default {
	data(){
		return {
			contact: [
				"13714712845",
				"986319647@Email"
			],
			fot2:[],
			media: [
				{
					src: require("../assets/images/mediaimgicon1.png"),
					url: "https://www.baidu.com/"
				},
				{
					src: require("../assets/images/mediaimgicon2.png"),
					url: "https://www.baidu.com/"
				},
				{
					src: require("../assets/images/mediaimgicon3.png"),
					url: "https://www.baidu.com/"
				},
				{
					src: require("../assets/images/mediaimgicon4.png"),
					url: "https://www.baidu.com/"
				}
			],
			er: require("../assets/images/erimg1.jpg"),
		}
	},
	created(){
		if(window.localStorage.getItem("fot") == null || window.localStorage.getItem("fot") ==""){
			this.$axios.post('/api/Column/getConfig').then(res=>{
				this.fot2=res.data;
				window.localStorage.setItem("fot",JSON.stringify(res.data));
			}).catch(error=>{
				console.log(error);
			});
		}else{
			this.fot2=JSON.parse(window.localStorage.getItem("fot"));
		}
		
	},
	mounted() {
		this.getAppMessage();  //获取APP信息
	},
	computed: {
		...mapState(['erimgfz'])
	},
	methods:{
		proaltHide(){
			$(".proinfoAlt").fadeOut();
		},
		async getAppMessage(){
			let er = await this.$axios.post('/api2/portal/article/site/getById',{id: '1498608633345578689'});
			
			this.$store.state.erimgfz = er.data.wechatImg1;
		},

	},
}
</script>

<style scoped>
.fade-enter-active,.fade-leave-active{
  transition: all 0.6s linear;
}
.fade-enter,.fade-leave-to{
  opacity: 0;
}
</style>